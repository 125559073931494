import { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import { Box, Grid, Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAuthContext } from '../hooks/useAuthContext'

const Dashboardcontent = () => {

  const check = localStorage.getItem('master')
  const { master, dispatch } = useAuthContext()

  const [allCnts, setAllCnts] = useState('')

  useEffect(() => {
    const fetchDashCnts = async () => {
      if (check) {
        const response = await fetch('/server/master/getAllCounts', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${master.token}`
          }
        })

        console.log(check)
        if (response.ok) {
          const json = await response.json()
          setAllCnts(json)
        } else {
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }
      }
      else {
        dispatch({ type: 'LOGOUT', isAuthenticated: false })
      }
    }

    fetchDashCnts()
  }, [])

  return (
    <div>
      <Container>
        <Typography sx={{ p: 2, px: 0 }} variant="h4">Dashboard</Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} component={Link} to={'doctors'} style={{ textDecoration: 'none' }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Doctors</Typography>
                  <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.dcCt}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} component={Link} to={'patients'} style={{ textDecoration: 'none' }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Patients</Typography>
                  <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.ptCT}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} component={Link} to={'questionnaires'} style={{ textDecoration: 'none' }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Journeys</Typography>
                  <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.qeCt}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4} component={Link} to={'scorecards'} style={{ textDecoration: 'none' }}>
              <Card variant="outlined">
                <CardContent>
                  <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Score Cards</Typography>
                  <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.scCT}</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

export default Dashboardcontent
