import { createContext, useReducer } from "react";

export const PatientsContext = createContext()

export const patientsReducer = (state, action) => {
    switch (action.type) {
        case 'LIST_PATIENTS':
            return {
                patients: action.payload
            }
        case 'ASSIGN_DOCTOR':
            return {
                patients: state.patients.map((patient) => (patient._id === action.payload._id ? action.payload : patient))
            }
        default:
            return state
    }
}

export const PatientsContextProvider = ({ children }) => {
    const [state, dispatchPatient] = useReducer(patientsReducer, {
        patients: null
    })

    return (
        <PatientsContext.Provider value={{ ...state, dispatchPatient }}>
            {children}
        </PatientsContext.Provider>
    )
}
