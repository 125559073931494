import { useRoutes, Navigate } from 'react-router-dom';  //Navigate

import { useAuthContext } from './hooks/useAuthContext'

// layouts
import Dashboard from './Dashboard/Dashboard'
import Dashboardcontent from './pages/Dashboardcontent';

import SignIn from './pages/SignIn';
import Doctors from './pages/Doctors'
import Patients from './pages/Patients'
import Questionnaires from './pages/Questionnaires'
import ScoreCards from './pages/ScoreCards'
import ViewQues from './pages/ViewQues'
import Results from './pages/Results'
import ResultsOfAI from './pages/ResultsOfAI'
import Report from './pages/Report'
import ReportOfAI from './pages/ReportOfAI'

import ViewDoctor from './pages/viaDoc/ViewDoctor'
import ViewQuesVd from './pages/viaDoc/ViewQues'
import ResultsVd from './pages/viaDoc/Results'
import ResultsOfAIVd from './pages/viaDoc/ResultsOfAIVd'
import ReportVd from './pages/viaDoc/Report'
import ReportOfAIVd from './pages/viaDoc/ReportOfAIVd'
// ----------------------------------------------------------------------

export default function Router() {

  const { master } = useAuthContext()

  const routes = useRoutes([
    {
      path: '/signin',
      element: !master ? <SignIn /> : <Navigate to="/" />,

    },
    {
      path: '/',
      element: master ? <Dashboard /> : <Navigate to="/signin" />,
      children: [
        { path: '/', element: master && master.mRole === "master" ? <Dashboardcontent /> : master && master.mRole === "admin" ? <Patients /> : <Navigate to="/signin" /> },
        { path: 'doctors', element: master && master.mRole === "master" ? <Doctors /> : <Navigate to="/signin" /> },
        { path: 'patients', element: master && (master.mRole === "master" || master.mRole === "admin") ? <Patients /> : <Navigate to="/signin" /> },
        { path: 'questionnaires', element: master && master.mRole === "master" ? <Questionnaires /> : <Navigate to="/signin" /> },
        { path: 'scorecards', element: master && master.mRole === "master" ? <ScoreCards /> : <Navigate to="/signin" /> },
        { path: 'view-questionnaires/:id', element: master && (master.mRole === "master" || master.mRole === "admin") ? <ViewQues /> : <Navigate to="/signin" /> },
        { path: 'ViewResults/:id', element: master && master.mRole === "master" ? <Results /> : <Navigate to="/signin" /> },
        { path: 'ViewResultsOfAI/:id', element: master && master.mRole === "master" ? <ResultsOfAI /> : <Navigate to="/signin" /> },
        { path: 'ViewReport/:id', element: master && (master.mRole === "master" || master.mRole === "admin") ? <Report /> : <Navigate to="/signin" /> },
        { path: 'ViewReportOfAI/:id', element: master && (master.mRole === "master" || master.mRole === "admin") ? <ReportOfAI /> : <Navigate to="/signin" /> },
        { path: 'viewdoctor/:docId', element: master && master.mRole === "master" ? <ViewDoctor /> : <Navigate to="/signin" /> },
        { path: 'doc/view-questionnaires/:id', element: master && master.mRole === "master" ? <ViewQuesVd /> : <Navigate to="/signin" /> },
        { path: 'doc/ViewResults/:id', element: master && master.mRole === "master" ? <ResultsVd /> : <Navigate to="/signin" /> },
        { path: 'doc/ViewResultsOfAI/:id', element: master && master.mRole === "master" ? <ResultsOfAIVd /> : <Navigate to="/signin" /> },
        { path: 'doc/ViewReport/:id', element: master && master.mRole === "master" ? <ReportVd /> : <Navigate to="/signin" /> },
        { path: 'doc/ViewReportOfAI/:id', element: master && master.mRole === "master" ? <ReportOfAIVd /> : <Navigate to="/signin" /> },
      ],
    }
  ]);

  return routes;
}
