import { createContext, useReducer } from "react";

export const DoctorsContext = createContext()

export const doctorsReducer = (state, action) => {
    switch (action.type) {
        case 'LIST_DOCTORS':
            return {
                doctors: action.payload
            }
        case 'ADD_DOCTOR':
            return {
                doctors: [action.payload, ...state.doctors]
            }
        case 'DEL_DOCTOR':
            return {
                doctors: state.doctors.filter((u) => u._id !== action.payload._id)
            }
        case 'UPDATE_DOCTOR':
            // const newArray = action.payload
            // const index = state.doctors.findIndex((u) => u._id === action.payload._id)
            // return {
            //     doctors: [
            //         ...state.doctors.slice(0, index),
            //         newArray,
            //         ...state.doctors.slice(index + 1)
            //     ]
            // }
            return {
                doctors: state.doctors.map((doctor) => (doctor._id === action.payload._id ? action.payload : doctor))
            }
        default:
            return state
    }
}

export const DoctorsContextProvider = ({ children }) => {
    const [state, dispatchDoctor] = useReducer(doctorsReducer, {
        doctors: null
    })

    return (
        <DoctorsContext.Provider value={{ ...state, dispatchDoctor }}>
            {children}
        </DoctorsContext.Provider>
    )
}
