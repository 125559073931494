import { createContext, useReducer } from "react";

export const QuesContext = createContext()

export const quesReducer = (state, action) => {
    switch (action.type) {
        case 'LIST_QUES':
            return {
                ques: action.payload
            }
        case 'UPDATE_QUES_CONTENT':
            return {
                ques: state.ques.map((ques) => (ques._id === action.payload._id ? action.payload : ques))
            }
        default:
            return state
    }
}

export const QuesContextProvider = ({ children }) => {
    const [state, dispatchQues] = useReducer(quesReducer, {
        ques: null
    })

    return (
        <QuesContext.Provider value={{ ...state, dispatchQues }}>
            {children}
        </QuesContext.Provider>
    )
}
