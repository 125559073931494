import * as React from 'react';
import { useState, useEffect } from "react"
import { withStyles, makeStyles } from "@mui/styles";

import { Link, useParams } from 'react-router-dom';
import { Box, Grid, Container } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useStyles from './styles';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';

import TextareaAutosize from '@mui/base/TextareaAutosize';

import { useAuthContext } from '../../hooks/useAuthContext'

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

function createData(cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8) {
    return { cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8 };
}

const QuesResCards = withStyles((theme) => ({
    root: {
        minHeight: "175px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
}))(Card);

const ResultsOfAIVd = () => {

    const [cliNotes, setCliNotes] = useState('')

    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    let { id } = useParams()

    const { master, dispatch } = useAuthContext()

    const [curPat, setCurPat] = useState('')

    const [users, setUsers] = useState(null)

    const [qaans, setQaans] = useState(null);
    const [expanded, setExpanded] = useState(false)

    const [tqns, setTqns] = useState(null)

    const [hwCrdClr, setHwCrdClr] = useState("#ccc")
    const [hwCrdClrTxt, setHwCrdClrTxt] = useState("")
    const [imCrdClr, setImCrdClr] = useState("#ccc")
    const [imCrdClrTxt, setImCrdClrTxt] = useState("")
    const [dpCrdClr, setDpCrdClr] = useState("#ccc")
    const [dpCrdClrTxt, setDpCrdClrTxt] = useState("")
    
    const [swa2Clr, setSwa2Clr] = useState("#ccc")
    const [swa2ClrTxt, setSwa2ClrTxt] = useState("")

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const check = localStorage.getItem('master')
            if (check) {
                const response = await fetch('/server/ques/view-result/' + id, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json = await response.json()

                const response0 = await fetch('/server/patients/' + json[0].patAggr[0]._id, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json0 = await response0.json()

                if (response0.ok) {
                    setCurPat(json0)
                }

                if (response.ok) {
                    setUsers(json[0])
                    setQaans(json[0].questions)
                    console.log(json)
                    setCliNotes(json[0].patient_notes)

                    let tmpQns = json[0].questions.length
                    setTqns(tmpQns)


                    // For No.of Zeros (0's)
                    let hw_val = parseInt(Number(json[0].score.num_total.num_of_zeros))

                    let n = 0;
                    if (hw_val >= 40 && hw_val <= 57) {
                        n = 1;
                        setHwCrdClr("rgb(255,0,0)");
                        setHwCrdClrTxt('Very high liklihood of getting AI(Autoimmune) condition / V high proneness. Recovery from it could also be slower')
                    } else if (hw_val >= 30 && hw_val <= 40) {
                        n = 2;
                        setHwCrdClr("rgb(255,192,0)");
                        setHwCrdClrTxt('High liklihood of getting AI condition')
                    } else if (hw_val >= 15 && hw_val <= 30) {
                        n = 3;
                        setHwCrdClr("rgb(255,255,0)");
                        setHwCrdClrTxt('Likely to be prone to getting an AI condition.')
                    } else if (hw_val < 15) {
                        n = 4;
                        setHwCrdClr("rgb(255,0,250)");
                        setHwCrdClrTxt('Less likely. But caution required.')
                    }

                    // For No.of Ones (1's)
                    let im_val = parseInt(Number(json[0].score.num_total.num_of_ones))

                    let o = 0;
                    if (im_val >= 40 && im_val <= 57) {
                        n = 1;
                        setImCrdClr("rgb(255,192,0)");
                        setImCrdClrTxt('High liklihood of getting AI condition.')
                    } else if (im_val >= 30 && im_val <= 40) {
                        n = 2;
                        setImCrdClr("rgb(255,255,0)");
                        setImCrdClrTxt('Likely to be prone to getting an AI condition.')
                    } else if (im_val >= 15 && im_val <= 30) {
                        n = 3;
                        setImCrdClr("rgb(255,0,250)");
                        setImCrdClrTxt('Less likely. But caution required.')
                    } else if (im_val < 15) {
                        n = 4;
                        setImCrdClr("rgb(255,0,250)");
                        setImCrdClrTxt('Less likely. But caution required.')
                    }

                    // For No.of Twos (2's)
                    let dp_val = parseInt(Number(json[0].score.num_total.num_of_twos));

                    let p = 0;

                    if (dp_val >= 40 && dp_val <= 57) {
                        n = 1;
                        setDpCrdClr("rgb(255,0,250)");
                        setDpCrdClrTxt('Highly unlikely. Least proneness to AI condition.')
                    } else if (dp_val >= 30 && dp_val <= 40) {
                        n = 2;
                        setDpCrdClr("rgb(255,255,0)");
                        setDpCrdClrTxt('Less likely.')
                    } else if (dp_val >= 15 && dp_val <= 30) {
                        n = 3;
                        setDpCrdClr("rgb(255,192,0)");
                        setDpCrdClrTxt('Likely. Caution required.')
                    } else if (dp_val < 15) {
                        n = 4;
                        setDpCrdClr("rgb(255,0,0)");
                        setDpCrdClrTxt('High liklihood of getting AI condition.')
                    }
                    
                    
                    // For Step 2 - Result Analysis
                    let swseta2 = parseInt(Number(json[0].score.set_wise.set_a_2));

                    let q = 0;

                    if (swseta2 >= 20 && swseta2 <= 30) {
                        q = 1;
                        setSwa2Clr("rgb(255,0,0)")
                        setSwa2ClrTxt('Very High liklihood.')
                    } else if (swseta2 >= 10 && swseta2 <= 20) {
                        q = 2;
                        setSwa2Clr("rgb(255,192,0)")
                        setSwa2ClrTxt('High liklihood.')
                    } else if (swseta2 < 10) {
                        q = 3;
                        setSwa2Clr("rgb(255,0,250)")
                        setSwa2ClrTxt('Modify and mend your habits to enhance your health further.')
                    }

                }

                // Status and color 
                let allStatus = [
                    "Very bad",
                    "Bad",
                    "Fairly bad",
                    "Tipping point",
                    "Fairly good",
                    "Good",
                    "Very good",
                ];
                let allStatusDis = [
                    "Very high",
                    "High",
                    "Fairly high",
                    "Tipping point",
                    "Fairly low",
                    "Low",
                    "Very low",
                ];

            }
            else {
                dispatch({ type: 'LOGOUT', isAuthenticated: false })
            }
        }

        fetchUsers()
    }, [])

    const classes = useStyles();

    const handleSubmit = async (e) => {
        const check = localStorage.getItem('master')
        if (check) {
            e.preventDefault()

            const clNote = { cliNotes }

            console.log(clNote)
            // console.log(users._id)

            const response = await fetch('/server/getAllUsers/saveCliNotes/' + users._id, {
                method: 'POST',
                body: JSON.stringify(clNote),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${master.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                setError(json.error)
                setEmptyFields(json.emptyFields)
            }
            if (response.ok) {
                setError(null)
                setEmptyFields([])
                alert('Notes Updated')
            }
        }
        else {
            dispatch({ type: 'LOGOUT', isAuthenticated: false })
        }
    }


    //console.log(users && users.score.health_wellness_index.nnpsp_ratio)

    return (

        <>
            {users &&
                <>
                    <Card variant="outlined">
                        <CardContent>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Typography color="text.primary">
                                    <Link to='/doctors'>Doctors List</Link>
                                </Typography>
                                <Typography color="text.primary">
                                    <Link to={'/viewdoctor/' + (curPat.patsDoc ? curPat.patsDoc._id : '')}>Doctor {curPat.patsDoc ? curPat.patsDoc.firstname : ''}</Link>
                                </Typography>
                                <Typography>
                                    <Link to={'/doc/view-questionnaires/' + users.patAggr[0]._id}>Journeys of {curPat.patient ? curPat.patient.firstname : ''}</Link>
                                </Typography>
                                <Typography>
                                    Journey Result
                                </Typography>
                            </Breadcrumbs>
                        </CardContent>
                    </Card>

                    <Paper className={classes.paep} sx={{ width: '100%', overflow: 'hidden', p: 3 }}>

                        <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Result analysis (General)
                            </Typography>
                            <Grid container spacing={2} sx={{ pb: 2 }}>

                                <Grid item xs={12} md={4}>
                                    <QuesResCards
                                        variant="outlined"
                                        sx={{ backgroundColor: hwCrdClr }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{ textAlign: "center", p: 2, py: 0 }}
                                                component="h5"
                                                variant="h5"
                                            >
                                                0's
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: "center", pt: 1 }}
                                                variant="h5"
                                                color="text.primary"
                                            >
                                                {(
                                                    users.score.num_total.num_of_zeros
                                                )}
                                                <br />
                                                {hwCrdClrTxt}
                                            </Typography>
                                        </CardContent>
                                    </QuesResCards>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <QuesResCards
                                        variant="outlined"
                                        sx={{ backgroundColor: imCrdClr }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{ textAlign: "center", p: 2, py: 0 }}
                                                component="h5"
                                                variant="h5"
                                            >
                                                1's
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: "center", pt: 1 }}
                                                variant="h5"
                                                color="text.primary"
                                            >
                                                {(
                                                    users.score.num_total.num_of_ones
                                                )}
                                                <br />
                                                {imCrdClrTxt}
                                            </Typography>
                                        </CardContent>
                                    </QuesResCards>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <QuesResCards
                                        variant="outlined"
                                        sx={{ backgroundColor: dpCrdClr }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{ textAlign: "center", p: 2, py: 0 }}
                                                component="h5"
                                                variant="h5"
                                            >
                                                2's
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: "center", pt: 1 }}
                                                variant="h5"
                                                color="text.primary"
                                            >
                                                {(
                                                    users.score.num_total.num_of_twos
                                                )}
                                                <br />
                                                {dpCrdClrTxt}
                                            </Typography>
                                        </CardContent>
                                    </QuesResCards>
                                </Grid>

                            </Grid>
                        </Box>
                 
                        <Box>
                            <Typography sx={{ fontWeight: "bold" }}>
                                Result analysis
                            </Typography>
                            <Grid container spacing={2} sx={{ pb: 2 }}>

                                <Grid item xs={12} md={4}>
                                    <QuesResCards
                                        variant="outlined"
                                        sx={{ backgroundColor: swa2Clr }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{ textAlign: "center", p: 2, py: 0 }}
                                                component="h5"
                                                variant="h5"
                                            >
                                                Set A 2's
                                            </Typography>
                                            <Typography
                                                sx={{ textAlign: "center", pt: 1 }}
                                                variant="h5"
                                                color="text.primary"
                                            >
                                                {(
                                                    users.score.set_wise.set_a_2
                                                )}
                                                <br />
                                                {swa2ClrTxt}
                                            </Typography>
                                        </CardContent>
                                    </QuesResCards>
                                </Grid>
                            </Grid>
                        </Box>


                        <Accordion
                            expanded={expanded === "panel1"}
                            onChange={handleChange("panel1")}
                            sx={{ marginBottom: "20px" }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{ background: "#46BDC6" }}
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                    }}
                                    component="h5"
                                >
                                    Questions & Answers
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {qaans.map((qnData, qid) => (
                                                <TableRow key={"qaa" + qid}>
                                                    <TableCell>
                                                        <Typography sx={{ fontWeight: "normal" }}>
                                                            {qid + 1 + ". "}
                                                            {qnData.q}
                                                            <Typography
                                                                sx={{ fontWeight: "bold" }}
                                                                component={"span"}
                                                                variant={"body2"}
                                                            >
                                                                {"    "}
                                                                {qnData[qnData.selected]}
                                                            </Typography>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>

                        <Box>
                            <Grid container style={{ justifyContent: 'center' }} spacing={2} sx={{ pb: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <CardContent>
                                        <form className="updateNotes" onSubmit={handleSubmit}>
                                            <TextareaAutosize
                                                maxRows={10}
                                                aria-label="maximum height"
                                                placeholder="Enter notes about this patient"
                                                style={{ width: 600, height: 200 }}
                                                onChange={(e) => setCliNotes(e.target.value)}
                                                className={
                                                    (emptyFields || "").includes("cliNotes")
                                                        ? "error"
                                                        : ""
                                                }
                                                value={cliNotes}
                                            />
                                            <br />
                                            <Button type="submit" variant="contained">
                                                Save
                                            </Button>
                                            {error && <div className="error">{error}</div>}
                                        </form>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Box>

                        <Card variant="outlined"></Card>

                    </Paper>
                </>
            }

        </>

    )
}

export default ResultsOfAIVd
