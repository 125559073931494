import { createContext, useReducer, useEffect } from "react"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { master: action.payload, status: 'loggedIn' }
        case 'LOGOUT':
            return { master: null, status: 'loggedOut' }
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        master: JSON.parse(localStorage.getItem('master'))
    })

    useEffect(() => {
        const master = JSON.parse(localStorage.getItem('master'))

        if (master) {
            dispatch({ type: 'LOGIN', payload: master })
        }

        if (!master) {
            dispatch({ type: 'LOGOUT' })
        }
    }, [])

    console.log("AuthContext state: ", state)

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )

}