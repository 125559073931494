import * as React from "react";
import { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import Chip from '@mui/material/Chip';

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useAuthContext } from "../hooks/useAuthContext";

const ViewQues = () => {
  let { id } = useParams();

  const { master, dispatch } = useAuthContext();

  const [users, setUsers] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const check = localStorage.getItem("master");
      if (check) {
        const response = await fetch("/server/ques/view-questionnaires/" + id, {
          headers: {
            Authorization: `Bearer ${master.token}`,
          },
        });
        const json = await response.json();

        if (response.ok) {
          setUsers(json);
          console.log(id);
        }
      } else {
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchUsers();
  }, []);

  let quarterText = ["Jan - Mar", "Apr - Jun", "Jul - Sep", "Oct - Dec"];

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Card variant="outlined">
          <CardContent>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">
                <Link to="/patients">Patients List</Link>
              </Typography>
              <Typography color="text.primary">Journeys</Typography>
              {/* <Typography>
                                        ({users && users[0].patient_meta.email})
                                    </Typography> */}
            </Breadcrumbs>
          </CardContent>
        </Card>

        <Container maxWidth="sm" sx={{ p: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid item xs={8}>
              {users &&
                users.map((usr, index) => (
                  <Card variant="outlined" key={index} sx={{ my: 3 }}>
                    <CardContent>
                        
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Chip size="small" variant="filled" sx={usr.view_status == true ? { bgcolor: "#81c784" } : { bgcolor: "#ffeb3b" }} label={usr.view_status == true ? "Viewed" : "Pending"} />
                            <Chip size="small" variant="filled" sx={usr.patient_notes == null ? { bgcolor: "#ffeb3b" } : { bgcolor: "#81c784" }} label={usr.patient_notes == null ? "Not Analysed" : "Analysed"} />
                        </Box>
                        
                      <Typography
                        sx={{ fontSize: 22 }}
                        color="text.grey"
                        gutterBottom
                      >
                        {(usr.aidqnid == "6363a67082cd85ddf4e25174" ||
                        usr.aidqnid == "63f9b4d20a4d31daa474c106")
                          ? "My Body Signals"
                          : "Auto Immune"}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 16 }}
                        color="text.grey"
                        gutterBottom
                      >
                        {quarterText[
                          Math.floor(
                            (new Date(parseInt(usr.createdAt)).getMonth() + 3) /
                              3
                          ) - 1
                        ] +
                          " " +
                          new Date(parseInt(usr.createdAt)).getFullYear()}
                      </Typography>
                    </CardContent>
                    <CardActions>
                        { master.mRole === "master" ?
                      <Button
                        sx={{ width: "100%" }}
                        variant="outlined"
                        size="medium"
                        component={Link}
                        to={usr.q_type === 'autoimmune-propensity' ? '/ViewResultsOfAI/' + usr._id : '/ViewResults/' + usr._id}
                        color="primary"
                      >
                        View Results
                      </Button>
                        : '' }
                      <Button
                        sx={{ width: "100%" }}
                        variant="outlined"
                        size="medium"
                        component={Link}
                        to={usr.q_type === 'autoimmune-propensity' ? '/ViewReportOfAI/' + usr._id : '/ViewReport/' + usr._id}
                        color="primary"
                      >
                        View Report
                      </Button>
                    </CardActions>
                  </Card>
                ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewQues;
