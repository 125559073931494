import * as React from "react";
import { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@mui/styles";

import { Link, useParams } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useStyles from "./styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";

import { useAuthContext } from "../hooks/useAuthContext";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import"./Result.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.common.black,
    //color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.action.hover,
    border: "1px solid  rgba(224, 224, 224, 1)",
  },
}))(TableRow);

const StyledTablecell1 = withStyles((theme) => ({
  root: {
    position: "relative",
    right: "-25px",
    fontWeight: "bold",
  },
}))(TableCell);

const QuesResCards = withStyles((theme) => ({
  root: {
    minHeight: "175px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
}))(Card);

function createData(cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8) {
  return { cell1, cell2, cell3, cell4, cell5, cell6, cell7, cell8 };
}

const rows = [
  createData(
    "Condition Codes",
    "Immediate Care",
    "Poor",
    "Bad",
    "Tipping Point",
    "Good",
    "Excellent",
    "Amazing"
  ),
  createData(
    "Range Color Code",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),
  // createData('', 1.67, 1.54, 1.43, 1.33, 1.25, 1.11, 1.00 ),
  // createData('', 2.00, 1.67, 1.54, 1.43, 1.33, 1.25, 1.11),
];
const rowsDp = [
  createData(
    "Condition Codes",
      "Highly likely",
            "More likely",
            "Likely",
            "Tipping Point",
            "Unlikely",
            "More unlikely",
            "Highly unlikely",
  ),
  createData(
    "Range Color Code",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),  
];
const rows_tt1 = [
  createData(
    "Indices",
    "Immediate Care",
    "Poor",
    "Bad",
    "Tipping Point",
    "Good",
    "Excellent",
    "Amazing"
  ),
  createData(
    "Health and wellness",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),
];
const rows_tt2 = [
  createData(
    "Immunity",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),
];
const rows_tt3 = [
  createData(
    "Disease Proneness",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),
];
const rows_tt4 = [
  createData(
    "Metabolism and Assimilation",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "viloet",
    "purple"
  ),
];

const rows_haw_val_m = [
  createData("4000", "4000", "4400", "5000", "5600", "7200", "7600", "8000"),
];

const rows_haw_val_f = [
  createData("5200", "5200", "5720", "6500", "7280", "9360", "9880", "10400"),
];
const rows_im_val_m = [
  createData("2000", "2000", "2400", "3000", "3600", "5200", "5600", "6000"),
];

const rows_im_val_f = [
  createData("3900", "3900", "4290", "4875", "5460", "7020", "7410", "7800"),
];

// const rows_dp_val_m = [
//   createData("2205", "2205", "2430", "2790", "3150", "4050", "4275", "4500"),
// ];

// const rows_dp_val_f = [
//   createData("2925", "2925", "3218", "3656", "4095", "5265", "5558", "5850"),
// ];

const rows_dp_val_m = [
  //createData("4500", "4275", "4050", "3150", "2790", "2430", "2205", "2205"),
  createData("2250", "2250", "2475", "2813", "3150", "4050", "4275", "4500"),
];

const rows_dp_val_f = [
  //createData("5850", "5558", "5265", "4095", "3656", "3218", "2925", "2925"),
  createData("2925", "2925", "3218", "3656", "4095", "5265", "5558", "5850"),
];

const rows_ma_val_m = [
  createData("1715", "1715", "1890", "2170", "2450", "3150", "3325", "3500"),
];

const rows_ma_val_f = [
  createData("2275", "2275", "2503", "2844", "3185", "4095", "4323", "4550"),
];

// #FF0000, #FF7F00, #FFFF00, #00FF00, #0000FF, #4B0082, #9400D3

const Results = () => {
  const [cliNotes, setCliNotes] = useState("");
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  const { master, dispatch } = useAuthContext();

  const [users, setUsers] = useState(null);
  const [qaans, setQaans] = useState(null);
  const [tqns, setTqns] = useState(null);

  const [hwCrdClr, setHwCrdClr] = useState("#ccc");
  const [imCrdClr, setImCrdClr] = useState("#ccc");
  const [dpCrdClr, setDpCrdClr] = useState("#ccc");
  const [maCrdClr, setMaCrdClr] = useState("#ccc");
  const [rows_haw_vals, setRows_haw_vals] = useState([]);
  const [rows_im_vals, setRows_im_vals] = useState([]);
  const [rows_dp_vals, setRows_dp_vals] = useState([]);
  const [rows_ma_vals, setRows_ma_vals] = useState([]);
  const [auto_res_com, setAuto_res_com] = useState([]);
    
    const [ptId, setPtId] = useState(null)
    const [patName, setPatName] = useState(null)
    
    const [cwd, setCwd] = useState(true)
    
    const [hwiDos, setHwiDos] = useState([])
    const [hwiDonts, setHwiDonts] = useState([])
    const [imiDos, setImiDos] = useState([])
    const [imiDonts, setImiDonts] = useState([])
    const [dpiDos, setDpiDos] = useState([])
    const [dpiDonts, setDpiDonts] = useState([])
    const [maiDos, setMaiDos] = useState([])
    const [maiDonts, setMaiDonts] = useState([])
    

    const [activeTab, setActiveTab] = useState('dos'); 

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };



    const tableCellStyles = {
        padding: "8px",
        width: "50%",
        listStyleType: "none",
        margin: "0",
    };

    const thstyles = {
        color: "#fff",
        padding: "7px 0",
        borderRadius: "20px",
        margin: "0 8px 0",
        position: "relative",
    };

    const thimgstyles = {
        margin: "0px auto",
        background: "#128183",
        borderRadius: "50px",
        padding: "10px",

    };

    const backgsyles = {
        background: 'rgb(237 248 248)',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
    }

    function Imagecomp({ srcimg, size, nameimg }) {
        return (
            <img
                style={{ margin: '0 auto' }}
                src={srcimg}
                alt={nameimg}
                width={size}
                height={size}
            />
        );
    }
    
  useEffect(() => {
    const fetchUsers = async () => {
      const check = localStorage.getItem("master");
      //setGender(users.questions);

      if (check) {
        setLoading(true);
        const response = await fetch("/server/ques/view-result/" + id, {
          headers: {
            Authorization: `Bearer ${master.token}`,
          },
        });
        const json = await response.json();
          
        // Get the Do's and Don'ts Regulatory record
        const bs_data = await fetch("/server/ques/getBodySignalsReguls/body-signals-reguls", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })
        const bsd_json = await bs_data.json()
        const rrr = bsd_json[0].regulatories

        if (response.ok) {
          setUsers(json[0]);
          setQaans(json[0].questions);
          //console.log(json);
          setCliNotes(json[0].patient_notes);

          if (json[0].patAggr[0].gender == "Male") {
            setRows_haw_vals(rows_haw_val_m);
            setRows_im_vals(rows_im_val_m);
            setRows_dp_vals(rows_dp_val_m);
            setRows_ma_vals(rows_ma_val_m);
          } else {
            setRows_haw_vals(rows_haw_val_f);
            setRows_im_vals(rows_im_val_f);
            setRows_dp_vals(rows_dp_val_f);
            setRows_ma_vals(rows_ma_val_f);
          }

          setLoading(false);
        }
          
        let fullScoreCard = json[0].score;

        setPatName(json[0].patAggr[0].firstname)

        setPtId(json[0].userid);
          
        let tmpQns = json[0].questions.length;

        let hwinnp = parseFloat(
            (16 * 10 * tmpQns) / Number(fullScoreCard.health_wellness_index.nnp)
        ).toFixed(4);
        let iinnp = parseFloat(
            (12 * 10 * tmpQns) / Number(fullScoreCard.immunity_index.nnp)
        ).toFixed(4);
        let dpinnp = parseFloat(
            (9 * 10 * tmpQns) / Number(fullScoreCard.disease_proneness_index.nnp)
        ).toFixed(4);
        let mainnp = parseFloat(
            (7 * 10 * tmpQns) /
            Number(fullScoreCard.metabolsim_assimilation_index.nnp)
        ).toFixed(4);

        let hwi_rprt = rangeCheck("", hwinnp)
        // console.log(hwi_rprt)
        const health_cont = []
        if (hwi_rprt == "Immediate Care" || hwi_rprt == "Poor" || hwi_rprt == "Bad") {
            health_cont.push({ hwi_content: rrr[0].health })
        } else if (hwi_rprt == "Tipping Point") {
            health_cont.push({ hwi_content: rrr[1].health })
        } else if (hwi_rprt == "Good" || hwi_rprt == "Excellent" || hwi_rprt == "Amazing") {
            health_cont.push({ hwi_content: rrr[2].health })
        }

        let imi_rprt = rangeCheck("", iinnp)
        // console.log(imi_rprt)
        const immunity_cont = []
        if (imi_rprt == "Immediate Care" || imi_rprt == "Poor" || imi_rprt == "Bad") {
            immunity_cont.push({ imi_content: rrr[0].immunity })
        } else if (imi_rprt == "Tipping Point") {
            immunity_cont.push({ imi_content: rrr[1].immunity })
        } else if (imi_rprt == "Good" || imi_rprt == "Excellent" || imi_rprt == "Amazing") {
            immunity_cont.push({ imi_content: rrr[2].immunity })
        }

        let dpi_rprt = rangeCheck("", dpinnp)
        // console.log(dpi_rprt)
        const disprop_cont = []
        if (dpi_rprt == "Immediate Care" || dpi_rprt == "Poor" || dpi_rprt == "Bad") {
            disprop_cont.push({ dpi_content: rrr[0].disease_propensity })
        } else if (dpi_rprt == "Tipping Point") {
            disprop_cont.push({ dpi_content: rrr[1].disease_propensity })
        } else if (dpi_rprt == "Good" || dpi_rprt == "Excellent" || dpi_rprt == "Amazing") {
            disprop_cont.push({ dpi_content: rrr[2].disease_propensity })
        }

        let mai_rprt = rangeCheck("", mainnp)
        // console.log(mai_rprt)
        const metab_cont = []
        if (mai_rprt == "Immediate Care" || mai_rprt == "Poor" || mai_rprt == "Bad") {
            metab_cont.push({ mai_content: rrr[0].metabolism })
        } else if (mai_rprt == "Tipping Point") {
            metab_cont.push({ mai_content: rrr[1].metabolism })
        } else if (mai_rprt == "Good" || mai_rprt == "Excellent" || mai_rprt == "Amazing") {
            metab_cont.push({ mai_content: rrr[2].metabolism })
        }

        setHwiDos(health_cont[0].hwi_content.dos)
        setHwiDonts(health_cont[0].hwi_content.donts)
        setImiDos(immunity_cont[0].imi_content.dos)
        setImiDonts(immunity_cont[0].imi_content.donts)
        setDpiDos(disprop_cont[0].dpi_content.dos)
        setDpiDonts(disprop_cont[0].dpi_content.donts)
        setMaiDos(metab_cont[0].mai_content.dos)
        setMaiDonts(metab_cont[0].mai_content.donts)

        function rangeCheck(d, val) {
            let allStatus = [
                "Immediate Care",
                "Poor",
                "Bad",
                "Tipping Point",
                "Good",
                "Excellent",
                "Amazing",
            ];
            let allStatusDis = [
              "Highly likely",
              "More likely",
              "Likely",
              "Tipping Point",
              "Unlikely",
              "More unlikely",
              "Highly unlikely",
            ];
            // let allStatusDis = [
            //     "Highly unlikely",
            //     "More unlikely",
            //     "Unlikely",
            //     "Tipping Point",
            //     "Likely",
            //     "More likely",
            //     "Highly likely",
            // ];
            let status = "";
            let n = 3;
            if (val > 2.0) {
                n = 0;
            } else if (val <= 2.0 && val >= 1.8183) {
                n = 1;
            } else if (val <= 1.8182 && val >= 1.59) {
                n = 2;
            } else if (val <= 1.6 && val >= 1.4287) {
                n = 3;
            } else if (val <= 1.4286 && val >= 1.1111) {
                n = 4;
            } else if (val <= 1.1111 && val >= 1.0527) {
                n = 5;
            } else if (val <= 1.0526 && val >= 1.0) {
                n = 6;
            }

            if (d != "dis") {
                status = allStatus[n];
            } else {
                status = allStatusDis[n];
            }
            if (json[0] && json[0].connect_w_doctor) {
                if (json[0] && json[0].connect_w_doctor != "yes") {
                    setCwd(false);
                }
            } else {
                setCwd(false);
            }

            return status;
        }
          
          
          
          
          

        if (json[0].score.hasOwnProperty("auto_response_comments")) {
          var temp_comments = Object.keys(
            json[0].score.auto_response_comments
          ).map(function (k) {
            return json[0].score.auto_response_comments[k];
          });

          setAuto_res_com(temp_comments);
        }

        // Status and color
        let allStatus = [
          "Very bad",
          "Bad",
          "Fairly bad",
          "Tipping point",
          "Fairly good",
          "Good",
          "Very good",
        ];
        let allStatusDis = [
          "Very high",
          "High",
          "Fairly high",
          "Tipping point",
          "Fairly low",
          "Low",
          "Very low",
        ];

        setTqns(tmpQns);

        // For health_wellness_index
        let hw_val = parseFloat(
          (16 * 10 * tmpQns) / Number(json[0].score.health_wellness_index.nnp)
        ).toFixed(4);

        let status = "";
        let n = 0;
        if (hw_val > 2.0) {
          n = 1;
          setHwCrdClr("rgb(255 148 148)");
        } else if (hw_val <= 2.0 && hw_val >= 1.8183) {
          n = 2;
          setHwCrdClr("rgb(253 200 147)");
        } else if (hw_val <= 1.8182 && hw_val >= 1.59) {
          n = 3;
          setHwCrdClr("rgb(255 255 148)");
        } else if (hw_val <= 1.6 && hw_val >= 1.4287) {
          n = 4;
          setHwCrdClr("rgb(149 255 149)");
        } else if (hw_val <= 1.4286 && hw_val >= 1.1111) {
          n = 5;
          setHwCrdClr("rgb(149 149 255)");
        } else if (hw_val <= 1.1111 && hw_val >= 1.0527) {
          n = 6;
          setHwCrdClr("rgb(211 150 255)");
        } else if (hw_val <= 1.0526 && hw_val >= 1.0) {
          n = 7;
          setHwCrdClr("rgb(223 149 255)");
        }

        // For immunity_index
        let im_val = parseFloat(
          (12 * 10 * tmpQns) / Number(json[0].score.immunity_index.nnp)
        ).toFixed(4);

        let o = 0;
        if (im_val > 2.0) {
          n = 1;
          setImCrdClr("rgb(255 148 148)");
        } else if (im_val <= 2.0 && im_val >= 1.8183) {
          n = 2;
          setImCrdClr("rgb(253 200 147)");
        } else if (im_val <= 1.8182 && im_val >= 1.59) {
          n = 3;
          setImCrdClr("rgb(255 255 148)");
        } else if (im_val <= 1.6 && im_val >= 1.4287) {
          n = 4;
          setImCrdClr("rgb(149 255 149)");
        } else if (im_val <= 1.4286 && im_val >= 1.1111) {
          n = 5;
          setImCrdClr("rgb(149 149 255)");
        } else if (im_val <= 1.1111 && im_val >= 1.0527) {
          n = 6;
          setImCrdClr("rgb(211 150 255)");
        } else if (im_val <= 1.0526 && im_val >= 1.0) {
          o = 7;
          setImCrdClr("rgb(223 149 255)");
        }

        // For disease_proneness_index
        let dp_val = parseFloat(
          (9 * 10 * tmpQns) / Number(json[0].score.disease_proneness_index.nnp)
        ).toFixed(4);

        let p = 0;
        if (dp_val > 2.0) {
          n = 1;
          setDpCrdClr("rgb(255 148 148)");
        } else if (dp_val <= 2.0 && dp_val >= 1.8183) {
          n = 2;
          setDpCrdClr("rgb(253 200 147)");
        } else if (dp_val <= 1.8182 && dp_val >= 1.59) {
          n = 3;
          setDpCrdClr("rgb(255 255 148)");
        } else if (dp_val <= 1.6 && dp_val >= 1.4287) {
          n = 4;
          setDpCrdClr("rgb(149 255 149)");
        } else if (dp_val <= 1.4286 && dp_val >= 1.1111) {
          n = 5;
          setDpCrdClr("rgb(149 149 255)");
        } else if (dp_val <= 1.1111 && dp_val >= 1.0527) {
          n = 6;
          setDpCrdClr("rgb(211 150 255)");
        } else if (dp_val <= 1.0526 && dp_val >= 1.0) {
          p = 7;
          setDpCrdClr("rgb(223 149 255)");
        }
        // if (dp_val > 2.0) {
        //   n = 1;
        //   setDpCrdClr("rgb(223 149 255)");
          
        // } else if (dp_val <= 2.0 && dp_val >= 1.8183) {
        //   n = 2;
        //   setDpCrdClr("rgb(211 150 255)");
        // } else if (dp_val <= 1.8182 && dp_val >= 1.59) {
        //   n = 3;
        //   setDpCrdClr("rgb(149 149 255)");
        // } else if (dp_val <= 1.6 && dp_val >= 1.4287) {
        //   n = 4;
        //   setDpCrdClr("rgb(149 255 149)");
        // } else if (dp_val <= 1.4286 && dp_val >= 1.1111) {
        //   n = 5;
        //   setDpCrdClr("rgb(255 255 148)");
          
        // } else if (dp_val <= 1.1111 && dp_val >= 1.0527) {
        //   n = 6;
          
        //   setDpCrdClr("rgb(253 200 147)");
        // } else if (dp_val <= 1.0526 && dp_val >= 1.0) {
        //   p = 7;
        //   setDpCrdClr("rgb(255 148 148)");
        // }

        // For metabolsim_assimilation_index
        let ma_val = parseFloat(
          (7 * 10 * tmpQns) /
          Number(json[0].score.metabolsim_assimilation_index.nnp)
        ).toFixed(4);
        let q = 0;
        if (ma_val > 2.0) {
          n = 1;
          setMaCrdClr("rgb(255 148 148)");
        } else if (ma_val <= 2.0 && ma_val >= 1.8183) {
          n = 2;
          setMaCrdClr("rgb(253 200 147)");
        } else if (ma_val <= 1.8182 && ma_val >= 1.59) {
          n = 3;
          setMaCrdClr("rgb(255 255 148)");
        } else if (ma_val <= 1.6 && ma_val >= 1.4287) {
          n = 4;
          setMaCrdClr("rgb(149 255 149)");
        } else if (ma_val <= 1.4286 && ma_val >= 1.1111) {
          n = 5;
          setMaCrdClr("rgb(149 149 255)");
        } else if (ma_val <= 1.1111 && ma_val >= 1.0527) {
          n = 6;
          setMaCrdClr("rgb(211 150 255)");
        } else if (ma_val <= 1.0526 && ma_val >= 1.0) {
          q = 7;
          setMaCrdClr("rgb(223 149 255)");
        }
      } else {
        setLoading(false);
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchUsers();
  }, []);

  const classes = useStyles();

  let rows_nop = [];
  let rr = 0;
  for (let i = 1; i < 17; i++) {
    rows_nop.push(
      <TableCell sx={{ fontWeight: "medium" }}>
        {users && users.score.num_of_positive["AD-" + i]}
      </TableCell>
    );
    rr = Number(rr) + Number(users && users.score.num_of_positive["AD-" + i]);
  }

  let rows_non = [];
  let ss = 0;
  for (let j = 1; j < 17; j++) {
    rows_non.push(
      <TableCell sx={{ fontWeight: "medium" }}>
        {users && users.score.num_of_negative["AD-" + j]}
      </TableCell>
    );
    ss = Number(ss) + Number(users && users.score.num_of_negative["AD-" + j]);
  }

  let rows_noz = [];
  let tt = 0;
  for (let k = 1; k < 17; k++) {
    rows_noz.push(
      <TableCell sx={{ fontWeight: "medium" }}>
        {users && users.score.num_of_zeros["AD-" + k]}
      </TableCell>
    );
    tt = Number(tt) + Number(users && users.score.num_of_zeros["AD-" + k]);
  }

  let rows_sop = [];
  let uu = 0;
  for (let l = 1; l < 17; l++) {
    rows_sop.push(
      <TableCell sx={{ fontWeight: "medium" }}>
        {users && users.score.sum_of_positive["AD-" + l]}
      </TableCell>
    );
    uu = Number(uu) + Number(users && users.score.sum_of_positive["AD-" + l]);
  }

  let rows_son = [];
  let vv = 0;
  for (let m = 1; m < 17; m++) {
    rows_son.push(
      <TableCell sx={{ fontWeight: "medium" }}>
        {users && users.score.sum_of_negative["AD-" + m]}
      </TableCell>
    );
    vv = Number(vv) + Number(users && users.score.sum_of_negative["AD-" + m]);
  }

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    
    
    const hwidostab = hwiDos && hwiDos.map((dd1, index1) => dd1)
    const hwidontstab = hwiDonts && hwiDonts.map((dd2, index2) => dd2)

    const imidostab = imiDos && imiDos.map((dd3, index3) => dd3)
    const imidontstab = imiDonts && imiDonts.map((dd4, index4) => dd4)

    const dpidostab = dpiDos && dpiDos.map((dd5, index5) => dd5)
    const dpidontstab = dpiDonts && dpiDonts.map((dd6, index6) => dd6)

    const maidostab = maiDos && maiDos.map((dd7, index7) => dd7)
    const maidontstab = maiDonts && maiDonts.map((dd8, index8) => dd8)
    
    const alldoos = [...hwidostab, ...imidostab, ...dpidostab, ...maidostab]
    const alldonts = [...hwidontstab, ...imidontstab, ...dpidontstab, ...maidontstab]
    
    const noDupDoos = alldoos.filter((item, index) => alldoos.indexOf(item) === index)
    const noDupDonts = alldonts.filter((item1, index1) => alldonts.indexOf(item1) === index1)
    
    const tableData = [
        {
            id: 1,
            Dos: noDupDoos,
            Donts: noDupDonts,
        }
    ]


  return (
    <>
      {users && (
        <>
          <Card variant="outlined">
            <CardContent>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="text.primary">
                  <Link to="/patients">Patients List</Link>
                </Typography>
                <Typography color="text.primary">
                  <Link to={"/view-questionnaires/" + users.userid}>
                    Journeys
                  </Link>
                </Typography>
                <Typography color="text.primary">Results</Typography>
                {/*<Typography>({users.patient_meta.email})</Typography>*/}
              </Breadcrumbs>
            </CardContent>
          </Card>

          <Paper
            className={classes.paep}
            sx={{ width: "100%", overflow: "hidden", p: 3 }}
          >
            <Box>
              <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12} md={3}>
                  <QuesResCards
                    variant="outlined"
                    sx={{ backgroundColor: hwCrdClr }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Health and wellness
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {(
                          (16 * 10 * tqns) /
                          users.score.health_wellness_index.nnp
                        ).toFixed(4)}
                        {console.log(
                          (
                            (16 * 10 * tqns) /
                            users.score.health_wellness_index.nnp
                          ).toFixed(4) +
                          " " +
                          tqns +
                          " " +
                          users.score.health_wellness_index.nnp
                        )}
                      </Typography>
                    </CardContent>
                  </QuesResCards>
                </Grid>
                <Grid item xs={12} md={3}>
                  <QuesResCards
                    variant="outlined"
                    sx={{ backgroundColor: imCrdClr }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Immunity
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {(
                          (12 * 10 * tqns) /
                          users.score.immunity_index.nnp
                        ).toFixed(4)}
                      </Typography>
                    </CardContent>
                  </QuesResCards>
                </Grid>
                <Grid item xs={12} md={3}>
                  <QuesResCards
                    variant="outlined"
                    sx={{ backgroundColor: dpCrdClr }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Disease proneness
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {(
                          (9 * 10 * tqns) /
                          users.score.disease_proneness_index.nnp
                        ).toFixed(4)}
                      </Typography>
                    </CardContent>
                  </QuesResCards>
                </Grid>
                <Grid item xs={12} md={3}>
                  <QuesResCards
                    variant="outlined"
                    sx={{ backgroundColor: maCrdClr }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ textAlign: "center", p: 2, py: 0 }}
                        component="h5"
                        variant="h5"
                      >
                        Metabolism and Assimilation
                      </Typography>
                      <Typography
                        sx={{ textAlign: "center", pt: 1 }}
                        variant="h5"
                        color="text.primary"
                      >
                        {(
                          (7 * 10 * tqns) /
                          users.score.metabolsim_assimilation_index.nnp
                        ).toFixed(4)}
                      </Typography>
                    </CardContent>
                  </QuesResCards>
                </Grid>
              </Grid>
            </Box>


            <Accordion
              expanded={expanded === "panel0"}
              onChange={handleChange("panel0")}
              sx={{ marginBottom: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ background: "#46BDC6" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  component="h5"
                >
                  Indices
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "18px",
                    mt: 3
                  }}
                  component="h6"
                >
                  Health and wellness, Immunity, Metabolism and Assimilation
                </Typography>
                <TableContainer
                  className={classes.tablecontainercolors}
                  sx={{ mb: 2 }}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.cell1}>
                          <StyledTableCell
                            className={row.cell1}
                            style={{
                              border: "1px solid  rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.cell1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell2}
                          >
                            {row.cell2}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell3}
                          >
                            {row.cell3}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell4}
                          >
                            {row.cell4}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell5}
                          >
                            {row.cell5}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell6}
                          >
                            {row.cell6}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell7}
                          >
                            {row.cell7}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell8}
                          >
                            {row.cell8}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}

                      <StyledTableRow>
                        <StyledTableCell />
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" style={{ fontWeight: "bold" }}>
                                {"> "}2.0000
                              </td>

                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                2.0000
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.8182
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.6000
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.4286
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.1111
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.0526
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <td
                                align="right"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "5px",
                                }}
                              >
                                1.00
                              </td>
                            </tr>
                          </Table>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "18px",
                    mt: 3
                  }}
                  component="h6"
                >
                  Disease proneness
                </Typography>
                <TableContainer
                  className={classes.tablecontainercolors}
                  sx={{ mb: 2 }}
                  component={Paper}
                >
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableBody>
                      {rowsDp.map((row) => (
                        <StyledTableRow key={row.cell1}>
                          <StyledTableCell
                            className={row.cell1}
                            style={{
                              border: "1px solid  rgba(224, 224, 224, 1)",
                            }}
                          >
                            {row.cell1}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell2}
                          >
                            {row.cell2}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell3}
                          >
                            {row.cell3}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell4}
                          >
                            {row.cell4}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell5}
                          >
                            {row.cell5}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell6}
                          >
                            {row.cell6}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell7}
                          >
                            {row.cell7}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            className={row.cell8}
                          >
                            {row.cell8}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}

                      <StyledTableRow>
                        <StyledTableCell />
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" style={{ fontWeight: "bold" }}>
                              {"> "}2.0000
                              </td>

                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                2.0000
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.8182
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.6000
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.4286
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.1111
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <StyledTablecell1
                                style={{ fontWeight: "bold" }}
                                align="right"
                              >
                                1.0526
                              </StyledTablecell1>
                            </tr>
                          </Table>
                        </StyledTableCell>
                        <StyledTableCell style={{ padding: 0 }}>
                          <Table>
                            <tr>
                              <td align="left" />
                              <td
                                align="right"
                                style={{
                                  fontWeight: "bold",
                                  paddingRight: "5px",
                                }}
                              >
                                1.00
                              </td>
                            </tr>
                          </Table>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ marginBottom: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ background: "#46BDC6" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  component="h5"
                >
                  Questions & Answers
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    {/* <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography component="h5" variant="h5">
                                  
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead> */}
                    <TableBody>
                      {qaans.map((qnData, qid) => (
                        <TableRow key={"qaa" + qid}>
                          <TableCell>
                            <Typography sx={{ fontWeight: "normal" }}>
                              {qid + 1 + ". "}
                              {qnData.q}
                              <Typography
                                sx={{ fontWeight: "bold" }}
                                component={"span"}
                                variant={"body2"}
                              >
                                {"    "}
                                {qnData[qnData.selected]}
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{ marginBottom: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                aria-controls="panel2bh-content"
                style={{ background: "#46BDC6" }}
                id="panel2bh-header"
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  component="h5"
                >
                  Calculating (Number OFs) and (Sum OFs) 
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.usecl}>
                  <TableContainer sx={{ mb: 3 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow key={"prime"}>
                          <TableCell></TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Jatharagni
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Koshta and Ahara Rasa
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Ama</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Ajeerna
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Rasa Dhatu
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Rakta Dhatu
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Dhatwagni
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Udavarta
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Dhatusamya
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Vata
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Pitta
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Kapha
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Bala
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Mala
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Mutra
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Sweda
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover key={"cns1"}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Number Of Positives (NP)
                          </TableCell>
                          {rows_nop}
                          <TableCell>{rr}</TableCell>
                        </TableRow>
                        <TableRow hover key={"cns2"}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Number Of Negatives (NP)
                          </TableCell>
                          {rows_non}
                          <TableCell>{ss}</TableCell>
                        </TableRow>
                        <TableRow hover key={"cns3"}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Number Of Zeros (NZ)
                          </TableCell>
                          {rows_noz}
                          <TableCell>{tt}</TableCell>
                        </TableRow>
                        <TableRow hover key={"cns4"}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Sum Of Positives (SP)
                          </TableCell>
                          {rows_sop}
                          <TableCell>{uu}</TableCell>
                        </TableRow>
                        <TableRow hover key={"cns5"}>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Sum Of Negatives (SN)
                          </TableCell>
                          {rows_son}
                          <TableCell>{vv}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{ marginBottom: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                style={{ background: "#46BDC6" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  component="h5"
                >
                  Calculating Ratio Of Positives And Ratio Of Negatives
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer sx={{ mb: 3 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow key={"cns6"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          INDICES
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Normalized (Number of +ves x 10) (NNP)
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Sum of Positives (SP)
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Normalized (Number of -ves x 10) (NNN)
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Sum of Negatives (SN)
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Ratio of Positives (NNP/SP)
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Ratio of Negatives (NNN/SN)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow hover key={"cns7"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Health and wellness index (16 ADs)
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.nnp}
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.sp}
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.nnn}
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.sn}
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.nnpsp_ratio}
                        </TableCell>
                        <TableCell>
                          {users.score.health_wellness_index.nnnsn_ratio}
                        </TableCell>
                      </TableRow>
                      <TableRow hover key={"cns8"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Immunity index (12 ADs)
                        </TableCell>
                        <TableCell>{users.score.immunity_index.nnp}</TableCell>
                        <TableCell>{users.score.immunity_index.sp}</TableCell>
                        <TableCell>{users.score.immunity_index.nnn}</TableCell>
                        <TableCell>{users.score.immunity_index.sn}</TableCell>
                        <TableCell>
                          {users.score.immunity_index.nnpsp_ratio}
                        </TableCell>
                        <TableCell>
                          {users.score.immunity_index.nnnsn_ratio}
                        </TableCell>
                      </TableRow>
                      <TableRow hover key={"cns9"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Disease proneness index (9 ADs)
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.nnp}
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.sp}
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.nnn}
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.sn}
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.nnpsp_ratio}
                        </TableCell>
                        <TableCell>
                          {users.score.disease_proneness_index.nnnsn_ratio}
                        </TableCell>
                      </TableRow>
                      <TableRow hover key={"cns10"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Metabolism and Assimilation index (7 ADs)
                        </TableCell>
                        <TableCell>
                          {users.score.metabolsim_assimilation_index.nnp}
                        </TableCell>
                        <TableCell>
                          {users.score.metabolsim_assimilation_index.sp}
                        </TableCell>
                        <TableCell>
                          {users.score.metabolsim_assimilation_index.nnn}
                        </TableCell>
                        <TableCell>
                          {users.score.metabolsim_assimilation_index.sn}
                        </TableCell>
                        <TableCell>
                          {
                            users.score.metabolsim_assimilation_index
                              .nnpsp_ratio
                          }
                        </TableCell>
                        <TableCell>
                          {
                            users.score.metabolsim_assimilation_index
                              .nnnsn_ratio
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow hover key={"cns11"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          "Needs immediate care" (23 Qs)
                        </TableCell>
                        <TableCell>
                          {users.score.needs_immediate_care}
                        </TableCell>
                        <TableCell>
                          Minimum 12 and above Qs should be YES
                        </TableCell>
                      </TableRow>
                      <TableRow hover key={"cns12"}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Post covid health status indicator
                        </TableCell>
                        <TableCell>
                          {users.score.post_covid_health_status_indicator}
                        </TableCell>
                        <TableCell>
                          Check mark for more than 15 - status is "Bad", More
                          than 20 - Very bad. More than 25 - Needs immediate
                          care.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{ marginBottom: "20px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: "40px" }} />}
                aria-controls="panel2bh-content"
                style={{ background: "#46BDC6" }}
                id="panel2bh-header"
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                  component="h5"
                >
                  Indices - Condition Codes - Range Color Code
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <TableContainer
                    className={classes.tablecontainercolors}
                    sx={{ mb: 2 }}
                    component={Paper}
                  >
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableBody>
                        {rows_tt1.map((row) => (
                          <StyledTableRow key={row.cell1}>
                            <StyledTableCell
                              className={row.cell1}
                              style={{
                                border: "1px solid  rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.cell1}
                              {row.cell1 == "Health and wellness"
                                ? " - " + users.score.health_wellness_index.nnp
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell2}
                            >
                              {row.cell2}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell3}
                            >
                              {row.cell3}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell4}
                            >
                              {row.cell4}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell5}
                            >
                              {row.cell5}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell6}
                            >
                              {row.cell6}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell7}
                            >
                              {row.cell7}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell8}
                            >
                              {row.cell8}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        {rows_haw_vals.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td
                                    align="left"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"< "} {row.cell1}
                                  </td>

                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell2}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell3}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell4}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell5}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell6}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell7}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <td
                                    align="right"
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {row.cell8}
                                  </td>
                                </tr>
                              </Table>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {rows_tt2.map((row) => (
                          <StyledTableRow key={row.cell1}>
                            <StyledTableCell
                              className={row.cell1}
                              style={{
                                border: "1px solid  rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.cell1}
                              {" - "}
                              {users.score.immunity_index.nnp}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell2}
                            >
                              {row.cell2}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell3}
                            >
                              {row.cell3}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell4}
                            >
                              {row.cell4}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell5}
                            >
                              {row.cell5}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell6}
                            >
                              {row.cell6}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell7}
                            >
                              {row.cell7}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell8}
                            >
                              {row.cell8}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        {rows_im_vals.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td
                                    align="left"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"< "}
                                    {row.cell1}
                                  </td>

                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell2}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell3}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell4}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell5}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell6}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell7}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <td
                                    align="right"
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {row.cell8}
                                  </td>
                                </tr>
                              </Table>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {rows_tt3.map((row) => (
                          <StyledTableRow key={row.cell1}>
                            <StyledTableCell
                              className={row.cell1}
                              style={{
                                border: "1px solid  rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.cell1}
                              {" - "}
                              {users.score.disease_proneness_index.nnp}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell2}
                            >
                              {row.cell2}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell3}
                            >
                              {row.cell3}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell4}
                            >
                              {row.cell4}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell5}
                            >
                              {row.cell5}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell6}
                            >
                              {row.cell6}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell7}
                            >
                              {row.cell7}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell8}
                            >
                              {row.cell8}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}

                        {rows_dp_vals.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td
                                    align="left"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"< "}
                                    {row.cell1}
                                  </td>

                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell2}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell3}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell4}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell5}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell6}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell7}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <td
                                    align="right"
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    
                                    {row.cell8}
                                  </td>
                                </tr>
                              </Table>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {rows_tt4.map((row) => (
                          <StyledTableRow key={row.cell1}>
                            <StyledTableCell
                              className={row.cell1}
                              style={{
                                border: "1px solid  rgba(224, 224, 224, 1)",
                              }}
                            >
                              {row.cell1}
                              {" - "}
                              {users.score.metabolsim_assimilation_index.nnp}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell2}
                            >
                              {row.cell2}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell3}
                            >
                              {row.cell3}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell4}
                            >
                              {row.cell4}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell5}
                            >
                              {row.cell5}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell6}
                            >
                              {row.cell6}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell7}
                            >
                              {row.cell7}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              className={row.cell8}
                            >
                              {row.cell8}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                        {rows_ma_vals.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td
                                    align="left"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"< "}
                                    {row.cell1}
                                  </td>

                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell2}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell3}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell4}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell5}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell6}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <StyledTablecell1
                                    style={{ fontWeight: "bold" }}
                                    align="right"
                                  >
                                    {row.cell7}
                                  </StyledTablecell1>
                                </tr>
                              </Table>
                            </StyledTableCell>
                            <StyledTableCell style={{ padding: 0 }}>
                              <Table>
                                <tr>
                                  <td align="left" />
                                  <td
                                    align="right"
                                    style={{
                                      fontWeight: "bold",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {row.cell8}
                                  </td>
                                </tr>
                              </Table>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
            <Box>
                
                {/* <Grid container spacing={2} sx={{ pb: 2 }}>
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Typography
                      sx={{ textAlign: "center", p: 2, py: 0 }}
                      component="h5"
                      variant="h5"
                    >
                      Automated Report
                    </Typography>
                    <List
                      sx={{
                        listStyleType: "disc",
                        pl: 2,
                        "& .MuiListItem-root": {
                          display: "list-item",
                        },
                      }}
                    >
                      {auto_res_com.map((coms) => (
                        <ListItem>{coms}</ListItem>
                      ))}
                    </List>

                    {
                      //users.score.auto_response_comments.ar9
                    }
                  </CardContent>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardContent>
                    <Typography
                      color="text.primary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Doctor Report
                    </Typography>
                    <div dangerouslySetInnerHTML={{ __html: cliNotes }}></div>
                  </CardContent>
                </Grid>
              </Grid> */ }
                
                <Typography
                    sx={{ textAlign: "center", p: 2, py: 0 }}
                    component="h5"
                    variant="h5"
                >
                    Automated Report
                </Typography>
                <Grid container spacing={2} sx={{ pb: 2 }}>

                    <Grid item xs={12} md={6} sx={{ margin: '0 auto' }}>
                
                        <ul style={{ padding: 0, display: "flex", margin: '10px 0 15px'  }}  className="tabd">
                            <li style={{ textAlign: "center", width: '50%', margin: '0', listStyleType: "none" }} onClick={() => handleTabClick('dos')}
          className={`tab ${activeTab === 'dos' ? 'active' : ''}`}>
                                <Typography style={{ background: '#138085', ...thstyles }}>Do's <span className="iyph">-</span> <br/> What will help</Typography>
                            </li>
                            <li style={{ textAlign: "center", width: '50%', margin: '0', listStyleType: "none" }} onClick={() => handleTabClick('donts')}
          className={`tab ${activeTab === 'donts' ? 'active' : ''}`}>
                                <Typography style={{ background: '#CE7672', ...thstyles }}>
                                    Dont's <span className="iyph">-</span> <br/> (Refrain or Avoid)
                                </Typography>
                            </li>
                        </ul>

                        {tableData.map((row) => (
                            <ul key={row.id}  style={{ margin: 0, padding: 0, display: "flex"}} className="tabsdos">
                                <li style={{ background: "#c1f4f7", ...tableCellStyles }} className={`tab ${activeTab === 'dos' ? 'active' : ''}`}>
                                    <ul style={{ padding: "30px" }}>
                                        {row.Dos.map((dosItem, index) => (
                                            <li style={{ padding: "5px 0" }} key={index}>
                                                {dosItem}
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                                <li style={{ background: "#F6E4E4", ...tableCellStyles }} className={`tab ${activeTab === 'donts' ? 'active' : ''}`}>
                                    <ul style={{ padding: "30px" }}>
                                        {row.Donts.map((dontItem, index) => (
                                            <li style={{ padding: "5px 0" }} key={index}>
                                                {dontItem}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        ))}
                        
                    </Grid>
                </Grid>
                
            </Box>
            {/* <Card variant="outlined">
              <CardContent>
                <Typography color="text.primary" sx={{ fontWeight: "bold" }}>
                  Doctor Report
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: cliNotes }}></div>
              </CardContent>
            </Card> */}
          </Paper>
        </>
      )}

      {loading ? (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
    </>
  );
};

export default Results;
