import { ScardContext } from "../context/ScardContext";

import { useContext } from "react";

export const useScardContext = () => {
    const context = useContext(ScardContext)

    if (!context) {
        throw Error('useScardContext must be used inside an ScardContextContextProvider')
    }

    return context
}