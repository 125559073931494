import React, { useState, useEffect } from 'react';
import { Navigate, Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import {
    styled,
    Paper,
    Button,
    Alert
} from "@mui/material";

import { useAuthContext } from '../../hooks/useAuthContext'

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const ViewDoctor = () => {

    const { master, dispatch } = useAuthContext()

    const [docDets, setDocDets] = useState('')
    const [allCnts, setAllCnts] = useState('')
    const [docpatients, setDocpatients] = useState('')

    const { docId } = useParams()

    useEffect(() => {
        const fetchDoctor = async () => {
            const check = localStorage.getItem('master')
            if (check) {
                const response = await fetch('/server/doctors/' + docId, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json = await response.json()

                if (response.ok) {
                    setDocDets(json)
                }
                else {
                    dispatch({ type: 'LOGOUT', isAuthenticated: false })
                }

                const response_cnt = await fetch('/server/doctors/getAllCounts/' + docId, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json_cnt = await response_cnt.json()
                if (response_cnt.ok) {
                    setAllCnts(json_cnt)
                }
                else {
                    dispatch({ type: 'LOGOUT', isAuthenticated: false })
                }

                const response_pts = await fetch('/server/doctors/getDoctorPatients/' + docId, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json_pts = await response_pts.json()

                if (response_pts.ok) {
                    setDocpatients(json_pts)
                }
                else {
                    dispatch({ type: 'LOGOUT', isAuthenticated: false })
                }

            }
            else {
                dispatch({ type: 'LOGOUT', isAuthenticated: false })
            }
        }

        fetchDoctor()
    }, [])

    return (
        <Container>
            <Typography variant="h4" sx={{ py: 2 }}>Doctor Details</Typography>
            <Grid container style={{ display: 'block' }}>

                <Link to='/doctors' style={{ float: 'right' }}>
                    <Button
                        variant="contained"
                        sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                    >
                        Back To Doctors List
                    </Button>
                </Link>

                <Grid item xs={12} md={4} style={{ textDecoration: 'none' }}>
                    <Card md={4}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                {docDets.firstname}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {docDets.lastname}
                            </Typography>
                            <Typography variant="h6" component="div">
                                {docDets.email}
                            </Typography>
                            <Typography variant="h6" component="div">
                                {docDets.phone}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Box sx={{ mt: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} style={{ textDecoration: 'none' }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Total Patients</Typography>
                                    <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.ptCT}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textDecoration: 'none' }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Total Journeys</Typography>
                                    <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.qsCT}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ pt: 1 }}>
                        <Grid item xs={12} md={4} style={{ textDecoration: 'none' }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Unopened Journeys</Typography>
                                    <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.uoQsCT}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textDecoration: 'none' }}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h5" variant="h5">Unupdated Journeys</Typography>
                                    <Typography sx={{ textAlign: "center", pt: 1 }} variant="h5" color="text.primary">{allCnts.upQsCT}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: 'auto',
                        overflow: 'auto',

                    }}
                >
                    <Container maxWidth="lg" sx={{ mt: 3, mb: 1 }}>
                        <React.Fragment>
                            <Typography variant="h6">Patients List</Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 400 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>Gender</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>Age Group</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>Location</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {docpatients &&
                                            docpatients.map((usr) => (
                                                <TableRow key={usr._id}>
                                                    <TableCell>{usr.firstname}</TableCell>
                                                    <TableCell>{usr.gender}</TableCell>
                                                    <TableCell>{usr.age_group}</TableCell>
                                                    <TableCell>{usr.geo_location}</TableCell>
                                                    <TableCell>
                                                        <Link to={'/doc/view-questionnaires/' + usr._id}>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                            >
                                                                View Journeys
                                                            </Button>
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    </Container>
                </Box>

            </Grid>
        </Container>
    )

}

export default ViewDoctor