import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import App from './App';

import { AuthContextProvider } from './context/AuthContext';
import { DoctorsContextProvider } from './context/DoctorsContext';
import { PatientsContextProvider } from './context/PatientsContext';
import { QuesContextProvider } from './context/QuesContext';
import { ScardContextProvider } from './context/ScardContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <DoctorsContextProvider>
        <PatientsContextProvider>
          <QuesContextProvider>
            <ScardContextProvider>
              <App />
            </ScardContextProvider>
          </QuesContextProvider>
        </PatientsContextProvider>
      </DoctorsContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

function storageChange(event) {
  if (event.key === 'master') {
    window.location.href = "signin";
  }
}
window.addEventListener('storage', storageChange, false)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

