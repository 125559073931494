
import {

  Badge,

  IconButton,
 
} from '@mui/material';
// utils

// components
import Iconify from '../../Components/Iconify/Iconify';


// ----------------------------------------------------------------------


export default function Notification() {

  return (
    <>
      <IconButton  sx={{ width: 40, height: 40 }}>
        <Badge color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

   
    </>
  );
}

// ----------------------------------------------------------------------

