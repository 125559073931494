import { QuesContext } from "../context/QuesContext";

import { useContext } from "react";

export const useQuesContext = () => {
    const context = useContext(QuesContext)

    if (!context) {
        throw Error('useQuesContext must be used inside an QuesContextProvider')
    }

    return context
}