import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { Link, useParams } from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";

import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Container } from '@mui/material';

import "./Report.css";
import "./animate.css";

const ReportOfAIVd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [hwi, setHwi] = useState("");
    const [dpi, setDpi] = useState("");
    const [mai, setMai] = useState("");
    const [imi, setImi] = useState("");
    const [cwd, setCwd] = useState(true);
    const [pNotes, setPNotes] = useState("");
    const [autoImmProns, setAutoImmProns] = useState("")

    const [sta2vl, setSta2vl] = useState("")

    const [users, setUsers] = useState(null);
    const [curPat, setCurPat] = useState('')
    const [patName, setPatName] = useState('')

    const [ptId, setPtId] = useState(null);

    const [hwCrdClr, setHwCrdClr] = useState("#ccc")
    const [hwCrdClrTxt, setHwCrdClrTxt] = useState("")
    const [imCrdClr, setImCrdClr] = useState("#ccc")
    const [imCrdClrTxt, setImCrdClrTxt] = useState("")
    const [dpCrdClr, setDpCrdClr] = useState("#ccc")
    const [dpCrdClrTxt, setDpCrdClrTxt] = useState("")

    const [swa2Clr, setSwa2Clr] = useState("#ccc")
    const [swa2ClrTxt, setSwa2ClrTxt] = useState("")

    let { id } = useParams();

    const { master, dispatch } = useAuthContext();

    useEffect(() => {
        //console.log(id);
        const fetchUsers = async () => {
            const check = localStorage.getItem("master");
            if (check) {
                setLoading(true);
                const response = await fetch("/server/ques/view-result/" + id, {
                    headers: {
                        Authorization: `Bearer ${master.token}`,
                    },
                });
                const json = await response.json();

                const response0 = await fetch('/server/patients/' + json[0].patAggr[0]._id, {
                    headers: {
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json0 = await response0.json()

                if (response0.ok) {
                    setCurPat(json0)
                    setPatName(json0.patient.firstname)
                }

                if (response.ok) {
                    setUsers(json);
                    //console.log(json);
                }
                else {
                    dispatch({ type: "LOGOUT", isAuthenticated: false });
                }

                let fullScoreCard = json[0].score;

                setPtId(json[0].userid);

                // console.log(json.score)
                let tmpQns = json[0].questions.length;

                let hwinnp = parseInt(Number(fullScoreCard.num_total.num_of_zeros))
                let iinnp = parseInt(Number(fullScoreCard.num_total.num_of_ones))
                let mainnp = parseInt(Number(fullScoreCard.num_total.num_of_twos))

                let vofsa2 = parseInt(Number(fullScoreCard.set_wise.set_a_2))

                setHwi(hwinnp)
                setMai(iinnp)
                setImi(mainnp)

                setSta2vl(vofsa2)

                const aIoutput = calcAImmuneProneness(hwinnp, iinnp, mainnp, vofsa2)


                let AImmPro = {
                    C: "Critical",
                    VH: "Very High",
                    H: "Highly Likely",
                    L: "Likely",
                    CR: "Caution Required",
                    F: "Healthy  Immunity",
                };

                function calcAImmuneProneness(hwinnp, iinnp, mainnp, vofsa2) {

                    let noOfZs = hwinnp,
                        noOf1s = iinnp,
                        noOf2s = mainnp,
                        noOf2sSetA = vofsa2;

                    let AImmmuneProneness = "";
                    let twos = "",
                        zeros = "";

                    if (noOf2s > 30) {
                        if (30 >= noOf2sSetA || noOf2sSetA > 20) {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (20 >= noOf2sSetA || noOf2sSetA > 10) {
                            AImmmuneProneness = "H";
                            return AImmmuneProneness;
                        }
                        if (noOf2sSetA <= 10) {
                            AImmmuneProneness = "CR";
                            return AImmmuneProneness;
                        }
                    }

                    if (noOfZs > 40) {
                        AImmmuneProneness = "F";
                        return AImmmuneProneness;
                    }

                    if (56 >= noOf2s || noOf2s > 40) {
                        twos = "VH";
                    }

                    if (56 >= noOfZs || noOfZs > 40) {
                        zeros = "LL";
                    }

                    if (40 >= noOf2s || noOf2s > 30) {
                        twos = "H";
                    }

                    if (40 >= noOfZs || noOfZs > 30) {
                        zeros = "L";
                    }

                    if (30 >= noOf2s || noOf2s > 15) {
                        twos = "L";
                    }

                    if (30 >= noOfZs || noOfZs > 15) {
                        zeros = "H";
                    }

                    if (noOf2s <= 15) { twos = "LL"; }

                    if (noOfZs <= 15) { zeros = "VH"; }

                    if (AImmmuneProneness == "") {

                        if (twos == "VH" && zeros == "H") {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (twos == "VH" && zeros == "VH") {
                            AImmmuneProneness = "C";
                            return AImmmuneProneness;
                        }
                        if (twos == "VH" && zeros == "CR") {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (twos == "H" && zeros == "H") {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (twos == "H" && zeros == "VH") {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (twos == "H" && zeros == "CR") {
                            AImmmuneProneness = "H";
                            return AImmmuneProneness;
                        }
                        if (twos == "L" && zeros == "H") {
                            AImmmuneProneness = "H";
                            return AImmmuneProneness;
                        }
                        if (twos == "L" && zeros == "VH") {
                            AImmmuneProneness = "VH";
                            return AImmmuneProneness;
                        }
                        if (twos == "L" && zeros == "CR") {
                            AImmmuneProneness = "L";
                            return AImmmuneProneness;
                        }
                        if (twos == "LL" && zeros == "H") {
                            AImmmuneProneness = "L";
                            return AImmmuneProneness;
                        }
                        if (twos == "LL" && zeros == "VH") {
                            AImmmuneProneness = "H";
                            return AImmmuneProneness;
                        }
                        if (twos == "LL" && zeros == "CR") {
                            AImmmuneProneness = "CR";
                            return AImmmuneProneness;
                        }
                        else {
                            AImmmuneProneness = "CR";
                            return AImmmuneProneness;
                        }
                    }

                }

                setAutoImmProns(AImmPro[aIoutput]);

                // For No.of Zeros (0's)
                let hw_val = parseInt(Number(json[0].score.num_total.num_of_zeros))

                let n = 0;
                if (hw_val >= 40 && hw_val <= 57) {
                    n = 1;
                    setHwCrdClr("rgb(255,0,0)");
                    setHwCrdClrTxt('Very high liklihood of getting AI(Autoimmune) condition / V high proneness. Recovery from it could also be slower')
                } else if (hw_val >= 30 && hw_val <= 40) {
                    n = 2;
                    setHwCrdClr("rgb(255,192,0)");
                    setHwCrdClrTxt('High liklihood of getting AI condition')
                } else if (hw_val >= 15 && hw_val <= 30) {
                    n = 3;
                    setHwCrdClr("rgb(255,255,0)");
                    setHwCrdClrTxt('Likely to be prone to getting an AI condition.')
                } else if (hw_val < 15) {
                    n = 4;
                    setHwCrdClr("rgb(255,0,250)");
                    setHwCrdClrTxt('Less likely. But caution required.')
                }

                // For No.of Ones (1's)
                let im_val = parseInt(Number(json[0].score.num_total.num_of_ones))

                let o = 0;
                if (im_val >= 40 && im_val <= 57) {
                    n = 1;
                    setImCrdClr("rgb(255,192,0)");
                    setImCrdClrTxt('High liklihood of getting AI condition.')
                } else if (im_val >= 30 && im_val <= 40) {
                    n = 2;
                    setImCrdClr("rgb(255,255,0)");
                    setImCrdClrTxt('Likely to be prone to getting an AI condition.')
                } else if (im_val >= 15 && im_val <= 30) {
                    n = 3;
                    setImCrdClr("rgb(255,0,250)");
                    setImCrdClrTxt('Less likely. But caution required.')
                } else if (im_val < 15) {
                    n = 4;
                    setImCrdClr("rgb(255,0,250)");
                    setImCrdClrTxt('Less likely. But caution required.')
                }

                // For No.of Twos (2's)
                let dp_val = parseInt(Number(json[0].score.num_total.num_of_twos));

                let p = 0;

                if (dp_val >= 40 && dp_val <= 57) {
                    n = 1;
                    setDpCrdClr("rgb(255,0,250)");
                    setDpCrdClrTxt('Highly unlikely. Least proneness to AI condition.')
                } else if (dp_val >= 30 && dp_val <= 40) {
                    n = 2;
                    setDpCrdClr("rgb(255,255,0)");
                    setDpCrdClrTxt('Less likely.')
                } else if (dp_val >= 15 && dp_val <= 30) {
                    n = 3;
                    setDpCrdClr("rgb(255,192,0)");
                    setDpCrdClrTxt('Likely. Caution required.')
                } else if (dp_val < 15) {
                    n = 4;
                    setDpCrdClr("rgb(255,0,0)");
                    setDpCrdClrTxt('High liklihood of getting AI condition.')
                }

                // For Step 2 - Result Analysis
                let swseta2 = parseInt(Number(json[0].score.set_wise.set_a_2));

                let q = 0;

                if (swseta2 >= 20 && swseta2 <= 30) {
                    q = 1;
                    setSwa2Clr("rgb(255,0,0)")
                    setSwa2ClrTxt('Very High liklihood.')
                } else if (swseta2 >= 10 && swseta2 <= 20) {
                    q = 2;
                    setSwa2Clr("rgb(255,192,0)")
                    setSwa2ClrTxt('High liklihood.')
                } else if (swseta2 < 10) {
                    q = 3;
                    setSwa2Clr("rgb(255,0,250)")
                    setSwa2ClrTxt('Modify and mend your habits to enhance your health further.')
                }

                if (json[0].patient_notes) {
                    let tnotes = '<tr class="tex-center bodyhead"><td style="display:inline-block;padding:5px 30px;font-size:18px">Expert Opinion</td></tr><tr><td><p>"' + json[0].patient_notes + "</p></td></tr>";
                    setPNotes(tnotes);
                }

                setLoading(false);
            } else {
                setLoading(false);
                dispatch({
                    type: "LOGOUT",
                    isAuthenticated: false,
                });
            }
        };

        fetchUsers();
    }, []);

    const tableStyle = {
        borderCollapse: 'collapse',
        margin: '0 auto',
        backgroundColor: '#c4d8d8',
    };

    const tableCellStyles = {
        border: '1px solid black',
        padding: '8px',

    };

    const tableData_img = [
        {
            id: 1,
            imgSrc: '/assets/images/proneness.png',
            text1: 'Proneness',
        },
        {
            id: 2,
            imgSrc: '/assets/images/affliction.png',
            text1: 'Onset of Affliction',
        },
        {
            id: 3,
            imgSrc: '/assets/images/manifestation.png',
            text1: 'Manifestation',
        },
        {
            id: 4,
            imgSrc: '/assets/images/intensity.png',
            text1: 'Intensity of Symptoms',
        },
        {
            id: 5,
            imgSrc: '/assets/images/avoid.png',
            text1: 'Refrain or Avoid',
        },
        {
            id: 6,
            imgSrc: '/assets/images/whatwillhelp.png',
            text1: 'What will help',
        },
    ];

    const rightSideTextsSets = [
        [
            'Based on your responses, the score is in the <b>“Critical”</b> range and chances of AutoImmune condition are “critically high”.',
            'The body will experience affliction approximately <b>within 6 months</b> ',
            'Manifestation <b>will likely be immediate</b> and sometimes may not be initially diagnosed as autoimmune in nature ',
            'When symptoms manifest, the intensity could be <b>SEVERE</b> in nature.',
            '<ol><li>Exercises of all sorts</li><li>Sleeping during the day</li><li>Intake of Curds, Milk, Ghee, Cheese (as applicable)</li><li>Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Closely monitoring body changes in seasonal variations</li><li>Rectifying dietary intake</li><li>Streamlining lifestyle habits</li><li>Regular tracking using the Body Signals assessment</li></ol>',
            'Very High'
        ],
        [
            'Based on your responses, the score is in the <b>“Very High”</b> range and chances of AutoImmune condition are “very highly likely”.',
            'The body will experience affliction approximately <b>within 6 months to 1 year</b>',
            'Manifestation <b>will likely be immediate</b> and sometimes may not be initially diagnosed as autoimmune in nature ',
            'When symptoms manifest, the intensity could be <b>VERY HIGH</b>  in nature.',
            '<ol><li>Exercises of all sorts</li><li>Sleeping during the day</li><li>Intake of Curds, Milk, Ghee, Cheese (as applicable)</li><li>Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Closely monitoring body changes in seasonal variations</li><li>Rectifying dietary intake</li><li>Streamlining lifestyle habits</li><li>Regular tracking using the Body Signals assessment</li></ol>',
        ],
        [
            'Based on your responses, the score is in the <b>“High”</b> range and chances of AutoImmune condition are “highly likely”.',
            'The body will experience affliction approximately <b>within 6 months to 2 year</b>',
            'Manifestation <b>will likely not be immediate</b> and sometimes may not be initially diagnosed as autoimmune in nature ',
            'When symptoms manifest, the intensity could be <b>HIGH </b> in nature.',
            '<ol><li>Exercises of all sorts</li><li>Sleeping during the day</li><li>Intake of Curds, Milk, Ghee, Cheese (as applicable)</li><li>Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Closely monitoring body changes in seasonal variations</li><li>Rectifying dietary intake</li><li>Streamlining lifestyle habits</li><li>Regular tracking using the Body Signals assessment</li></ol>',
        ],
        [
            'Based on your responses, the score is in the <b>“Likely”</b> range and chances of AutoImmune condition are “likely”.',
            'The body will experience affliction approximately <b>within 1 to 3 years</b>',
            'Manifestation <b>will likely not be immediate</b> and sometimes may not be initially diagnosed as autoimmune in nature ',
            'When symptoms manifest, the intensity could be <b>MODERATE</b> in nature.',
            '<ol><li>Exercises of all sorts</li><li>Sleeping during the day</li><li>Intake of Curds, Milk, Ghee, Cheese (as applicable)</li><li>Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Closely monitoring body changes in seasonal variations</li><li>Rectifying dietary intake</li><li>Streamlining lifestyle habits</li><li>Regular tracking using the Body Signals assessment</li></ol>',
        ],

        [
            'Based on your responses, the score is in the <b>“Caution Required”</b> range and chances of AutoImmune condition are “slim to nil”.',
            'The body will experience affliction approximately <b>within 1 to 3 years</b>',
            'Manifestation <b>will likely not be immediate</b> and sometimes may not be initially diagnosed as autoimmune in nature ',
            'When symptoms manifest, the intensity could be <b>MODERATE TO MILD</b> in nature.',
            '<ol><li>Exercises of all sorts</li><li>Sleeping during the day</li><li>Intake of Curds, Milk, Ghee, Cheese (as applicable)</li><li>Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Closely monitoring body changes in seasonal variations</li><li>Rectifying dietary intake</li><li>Streamlining lifestyle habits</li><li>Regular tracking using the Body Signals assessment</li></ol>',
        ],

        [
            'Based on your responses, the score is in the <b>“Healthy Immunity”</b> range and disease resistance capacity is good in general.',
            'None at this point in time ',
            'Very minimal chances at this point in time ',
            'None at this point in time',
            '<ol><li>Excess intake of vegetables, greens, and sprouts</li><li>Excess intake of dairy like Curds, Milk, Ghee, Cheese (as applicable)</li><li>Excess Intake of Meat and Fish (as applicable)</li><li>Late Dinners</li><li>Desserts</li></ol>',
            '<ol><li>Regular meal times</li><li>Early dinners</li><li>Moderation in exercises - preferably on empty stomach in the morning</li><liRegular tracking using the Body Signals assessment</li></ol>',
        ],
    ];

    const renderTableCell = (text) => {
        if (text.startsWith('<ol>') && text.endsWith('</ol>')) {
            return <div dangerouslySetInnerHTML={{ __html: text }} />;
        }

        else {
            // return <p>{text}</p>;
            const boldText = text.replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>');
            return <p dangerouslySetInnerHTML={{ __html: boldText }} />;
        }
    };

    const tableData = tableData_img.map((row, index) => ({
        ...row,
        text2: autoImmProns == 'Critical'
            ? renderTableCell(rightSideTextsSets[0][index]) : autoImmProns == 'Very High'
                ? renderTableCell(rightSideTextsSets[1][index]) : autoImmProns == 'Highly Likely'
                    ? renderTableCell(rightSideTextsSets[2][index]) : autoImmProns == 'Likely'
                        ? renderTableCell(rightSideTextsSets[3][index]) : autoImmProns == 'Caution required'
                            ? renderTableCell(rightSideTextsSets[4][index]) : autoImmProns == 'Healthy Immunity'
                                ? renderTableCell(rightSideTextsSets[5][index])
                                : '',
    }));
    
    function handleCwd() {
        console.log("connected");
    }

    function isEmpty(obj) {
        for (const property in obj) {
            return false;
        }
        return true;
    }

    const myHTML =
        '<table class="partab"><tr><td><h1 class="tex-center ylwclr">This is the Only Body We Have</h1></td></tr><tr><td><p class="quote tex-center">Understand what it has to say</p></td></tr><tr><td><h3 class="text-upper tex-center ylwclr">Dear ' + patName + '</h3></td></tr><tr><td class="pdfp"><p>Based on your responses, we are pleased to share your auto immune propensity analysis.  Please note, it is advisable to take the assessment once every 90 days.</p></td></tr><tr><td class="pdfp ylwclr"><span>Note: For chronic conditions please consult your local doctor before using this information.</span></td></tr></table><table class="partab" width="100%" style="border-spacing:15px;padding:0">' +
        '<tr class="pointsback dark"><td style="padding:0 15px" colspan="2" class="fulwidimgtex simle withcolr"><h3 class="text-center">AutoImmune Proneness Index</h3><div class="imgandtex"><img class="animate__animated animate__fadeInLeft" src="/assets/images/immunity_icon.png" alt="Immunity" /><p>' +
        autoImmProns +
        '</p></div><div class="infoarrow" style=" display: flex; justify-content: space-between; padding: 0;"><p style=" list-style: none;">Critical<br><br><img class="animate__animated animate__fadeInLeft" src="/assets/images/critical.jpg" alt="Immunity" /></p><p>Very <br> High<img class="animate__animated animate__fadeInLeft" src="/assets/images/veryhigh.jpg" alt="Immunity" /></p><p>Highly<br> Likely<img class="animate__animated animate__fadeInLeft" src="/assets/images/higlylikely.jpg" alt="Immunity" /></p> <p>Likely <br><br><img class="animate__animated animate__fadeInLeft" src="/assets/images/likely.jpg" alt="Immunity" /></p> <p>Caution <br/> required<img class="animate__animated animate__fadeInLeft" src="/assets/images/cautionrequired.jpg" alt="Immunity" /></p>  <p>Healthy <br> Immunity<img class="animate__animated animate__fadeInLeft" src="/assets/images/fit.jpg" alt="Immunity" /></p> </div><div class="arrow ' +
        autoImmProns +
        '" ></div></td></tr></table>';
    
    const myFooter =
        '<table width="100%" class="fotpart partab"><tr><td class="tex-center">If you have any questions please email to:<a class="tex-white" href="mailto: AskTheExperts@ayana.ai">AskTheExperts@ayana.ai</a></td></tr><tr><td><h4 class="tex-center" style="color:#72b4d6">Powered by Ayana</h4></td></tr></table>';

    return (

        <>
            <Card variant="outlined">
                <CardContent>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">
                            <Link to='/doctors'>Doctors List</Link>
                        </Typography>
                        <Typography color="text.primary">
                            <Link to={'/viewdoctor/' + (curPat.patsDoc ? curPat.patsDoc._id : '')}>Doctor {curPat.patsDoc ? curPat.patsDoc.firstname : ''}</Link>
                        </Typography>
                        <Typography>
                            <Link to={'/doc/view-questionnaires/' + (curPat.patient ? curPat.patient._id : '')}>Journeys of {curPat.patient ? curPat.patient.firstname : ''}</Link>
                        </Typography>
                        <Typography>
                            Journey Report
                        </Typography>
                    </Breadcrumbs>
                </CardContent>
            </Card>

            <div className="app">
                <div className="centered-div ques">
                    <Card className="cardstyle">
                        <div dangerouslySetInnerHTML={{ __html: myHTML }} />
                        
                        <>
                            <TableContainer sx={{ margin: '0 auto' }} component={Paper}>
                                <Table style={tableStyle}>

                                    <TableBody>
                                        {tableData.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell style={tableCellStyles}>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img src={row.imgSrc} alt={row.text1} width={60} height={60} style={{ margin: '0 auto' }} />
                                                        <p>{row.text1}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell style={tableCellStyles}>{row.text2}</TableCell>
                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Container maxWidth="sm" sx={{ backgroundColor: '#c4d8d8', margin: '0 auto' }}>
                                <Typography sx={{ py: 2 }}><b>Disclaimer</b>: Please note that the assessment and probability of proneness is based on the responses provided at this point in time. This assessment is liable to change based on the underlying medical conditions. Recovery from specific AutoImmune  conditions will require personalized care and custom treatment protocols. We are in the process of getting custom options for you on this platform.</Typography>
                            </Container>
                        </>

                        <div dangerouslySetInnerHTML={{ __html: myFooter }} />
                        {cwd ? (
                            ""
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    width: "100%",
                                    margin: "15px 0",
                                }}
                            >
                                <Button
                                    style={{
                                        background: "#1A8585",
                                        color: "white",
                                        textTransform: "none",
                                    }}
                                    onClick={handleCwd}
                                >
                                    Connect With a Doctor - Coming Soon!
                                </Button>{" "}
                            </div>
                        )}
                        {loading ? (
                            <Backdrop
                                sx={{
                                    color: "#fff",
                                    zIndex: (theme) => theme.zIndex.drawer + 1000,
                                }}
                                open={true}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        ) : (
                            ""
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ReportOfAIVd;
