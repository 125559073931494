import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import {
  Button,
  Paper,
  TextField,
  Box,
  Typography,
  Container,
  Alert
} from "@mui/material";

import Logo from "../Components/Logo/Logo";

const SignIn = () => {
  const btncolor = {
    backgroundColor: "#d53c81",
    color: "#fff",
  };
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isLoading, emptyFields } = useLogin()

  const handleSubmit = async (e) => {
    e.preventDefault()

    login(email, password)
  }
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography sx={{ textAlign: "center", p: 2, py: 0 }} component="h3" variant="h3">AYANA</Typography>
        <Typography sx={{ textAlign: "center", p: 1, py: 0, pb: 2 }} component="h6" variant="h6">Admin Panel</Typography>
        <Paper sx={{ p: 5 }}>

          <img
            style={{ width: 100, height: 100, margin: '0 auto' }}
            src={`assets/images/ayana-logo.png`}
            //srcSet={`assets/images/ayana-logo.png`}
            alt="Ayana"
            loading="lazy"
          />
          <Typography sx={{ textAlign: "center" }} component="h1" variant="h5">
            Sign In
          </Typography>

          <Box noValidate sx={{ mt: 1 }}>
            <form className="login" onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                inputProps={{ className: emptyFields?.includes('email') ? 'error' : '' }}
              />
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                inputProps={{ className: emptyFields?.includes('password') ? 'error' : '' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={btncolor}
                disabled={isLoading}
              >
                Sign In
              </Button>
            </form>
          </Box>

          {error && <Alert severity="error"><div className="error">{error}</div></Alert>}
        </Paper>
      </Box>
    </Container>
  );
}
export default SignIn