import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText, ListItem } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";

import { useAuthContext } from '../../hooks/useAuthContext'

NavBar.propTypes = {
  data: PropTypes.array,
};

export default function NavBar({ data = [], ...other }) {
  const { master, dispatch } = useAuthContext()
  return (
    <>
      <Box {...other}>

        <List disablePadding sx={{ px: 1 }}>
          {data.map((item, i) => (
            master && master.mRole == item.role ?
            <ListItem key={i} sx={{ p: 0, display: 'block' }}>
              <>
                <NavItem key={i} item={item} />
              </>
            </ListItem>
            : ''
          ))}
        </List>

      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  // const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen(!open);
  // };



  return (

    <>
      {info && info}


      <>
        <StyledNavItem
          component={RouterLink}
          to={path}
          sx={{
            p: 0,
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold",
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

          <ListItemText disableTypography primary={title} />
        </StyledNavItem>
      </>

    </>
  );
}
