import { useState } from "react"
import { useDoctorsContext } from "../hooks/useDoctorsContext"
import { useAuthContext } from '../hooks/useAuthContext'
import './doctorform.css'
import {
    TextField,
    styled,
    Paper,
    Typography,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl,
    Button,
    Alert,
    Grid
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material/";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const DoctorForm = () => {

    const { dispatchDoctor } = useDoctorsContext()
    const { master } = useAuthContext()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const spacingtitle = {
        padding: "20px 25px 10px",
        textAlign: "left",
    };

    const handleSubmit = async (e) => {
        const check = localStorage.getItem('master')
        if (!check) {
            setError('You must be logged in')
            return
        }

        e.preventDefault()

        const doctor = { firstname, lastname, phone, email, password }

        const response = await fetch('/server/doctors', {
            method: 'POST',
            body: JSON.stringify(doctor),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setError(json.error)
            setEmptyFields(json.emptyFields)
        }
        if (response.ok) {
            setError(null)
            setFirstname('')
            setLastname('')
            setPhone('')
            setEmail('')
            setPassword('')
            setEmptyFields([])
            dispatchDoctor({ type: 'ADD_DOCTOR', payload: json })
        }
    }

    return (

        <>

            <form className="create" onSubmit={handleSubmit}>
                <Item sx={{ px: 3 }} style={spacingtitle}>
                    <Typography variant="h5">Add New Doctor</Typography>
                </Item>
                <Item sx={{ px: 3 }}>
                    <Grid container>
                        <Grid md={6}>
                            <Item sx={{ pl: 0 }}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    id="firstname"
                                    name="firstname"
                                    onChange={(e) => setFirstname(e.target.value)}
                                    value={firstname}
                                    inputProps={{ className: emptyFields?.includes('firstname') ? 'error' : '' }}
                                />
                            </Item>
                        </Grid>
                        <Grid md={6}>
                            <Item sx={{ pr: 0 }}>
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    id="lastname"
                                    name="lastname"
                                    onChange={(e) => setLastname(e.target.value)}
                                    value={lastname}
                                    inputProps={{ className: emptyFields?.includes('lastname') ? 'error' : '' }}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                </Item>
                <Item sx={{ px: 3 }}>
                    <TextField
                        fullWidth
                        label="Phone"
                        id="phone"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        inputProps={{ className: emptyFields?.includes('phone') ? 'error' : '' }}
                    />
                </Item>
                <Item sx={{ px: 3 }}>
                    <TextField
                        fullWidth
                        label="Mail ID"
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        inputProps={{ className: emptyFields?.includes('email') ? 'error' : '' }}
                    />
                </Item>

                <Item sx={{ px: 3 }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            name="password"
                            autoComplete="password"
                            inputProps={{ className: emptyFields?.includes('password') ? 'error' : '' }}
                        />
                    </FormControl>
                </Item>

                <Item>
                    <Button
                        variant="contained"
                        sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                        type="submit"
                    >
                        Add Doctor
                    </Button>
                </Item>
            </form>

            {error && <Alert severity="error"><div className="error">{error}</div></Alert>}

        </>
    )

}

export default DoctorForm