import { useState, useEffect } from "react"

import { useAuthContext } from '../hooks/useAuthContext'
import { useScardContext } from '../hooks/useScardContext'
import { usePatientsContext } from '../hooks/usePatientsContext'
import { useDoctorsContext } from '../hooks/useDoctorsContext'

import {
    Container,
    Box,
    Grid,
    styled,
    Paper,
    Typography,
    Button,
    Alert
} from "@mui/material";

import { DataGrid, GridToolbarQuickFilter, GridActionsCellItem } from "@mui/x-data-grid";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                textAlign: 'right',
                m: 3,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const ScoreCards = () => {

    const { patients, dispatchPatient } = usePatientsContext()
    const { scards, dispatchScards } = useScardContext()
    const { doctors, dispatchDoctor } = useDoctorsContext()
    const { master, dispatch } = useAuthContext()

    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])
    const [firstname, setFirstname] = useState('')
    const [docname, setDocname] = useState('')
    const [scid, setScid] = useState('')
    const [curDoc, setCurDoc] = useState('')
    const [isEditing, setIsEditing] = useState(false)

    const check = localStorage.getItem('master')
    useEffect(() => {
        const fetchPatients = async () => {

            if (check) {

                const response1 = await fetch('/server/doctors/', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json1 = await response1.json()

                if (response1.ok) {
                    dispatchDoctor({ type: 'LIST_DOCTORS', payload: json1 })
                }
                else {
                    dispatch({ type: 'LOGOUT', isAuthenticated: false })
                }

                const response2 = await fetch('/server/master/getAllScoreCards/', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${master.token}`
                    }
                })

                const json2 = await response2.json()

                if (response2.ok) {
                    dispatchScards({ type: 'LIST_SCARDS', payload: json2 })
                }
                else {
                    dispatch({ type: 'LOGOUT', isAuthenticated: false })
                }
            }
            else {
                dispatch({ type: 'LOGOUT', isAuthenticated: false })
            }
        }

        fetchPatients()
    }, [dispatchScards, dispatchPatient, dispatchDoctor])

    const assignDoc = async (scid) => {

        if (!check) {
            setError('You must be logged in')
            return
        }

        setIsEditing(true)

        const response0 = await fetch('/server/master/getScoreCard/' + scid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })
        const json4 = await response0.json()

        if (!response0.ok) {
            setError(json4.error)
            setEmptyFields(json4.emptyFields)
            setCurDoc('')
        }
        if (response0.ok) {
            setFirstname(json4.patient_meta.email)
            setScid(json4._id)
            setDocname(json4.doctor)
            setError(null)
            setEmptyFields([])

            console.log(scid)
        }
    }

    const handleSubmit = async (e) => {

        if (!check) {
            setError('You must be logged in')
            return
        }

        e.preventDefault()

        const docdets = { docname }

        console.log(scid)

        const response0 = await fetch('/server/patients/assigndoctor/' + scid, {
            method: 'POST',
            body: JSON.stringify(docdets),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })

        const json4 = await response0.json()

        if (!response0.ok) {
            setError(json4.error)
            setEmptyFields(json4.emptyFields)
        }
        if (response0.ok) {
            dispatchScards({ type: 'ASSIGN_DOCTOR', payload: json4 })
            setError(null)
            setFirstname('')
            //setDocname('')
            setScid('')
            //setCurDoc('')
            setEmptyFields([])
            setIsEditing(false)
        }

    }

    const spacingtitle = {
        padding: "20px 25px 10px",
        textAlign: "left",
    };

    const columns = [
        {
            field: "patID",
            headerName: "ID",
            width: 100
        },
        {
            field: "pName",
            headerName: "Name",
            width: 250,
        },
        {
            field: "pGender",
            headerName: "Gender",
            width: 250,
        },
        {
            field: "quesName",
            headerName: "Journey",
            width: 250,
        },
        {
            field: "pAgeGroup",
            headerName: "Age Group",
            width: 200,
        },
        {
            field: "pGeoLoc",
            headerName: "Geo Location",
            width: 200,
        },
        {
            field: "pDoctor",
            headerName: "Doctor",
            width: 200,
        },
        // {
        //     field: 'actions',
        //     type: 'actions',
        //     headerName: "Action",
        //     width: 200,
        //     renderCell: (params) => {
        //         return (

        //             params.id[1] ? <><Button
        //                 variant="outlined"
        //                 color="info"
        //                 onClick={() => assignDoc(params.id[0])}
        //             >
        //                 Modify Doctor
        //             </Button> </> :
        //                 <Button
        //                     variant="outlined"
        //                     color="info"
        //                     onClick={() => assignDoc(params.id[0])}
        //                 >
        //                     Assign Doctor
        //                 </Button>

        //         )
        //     },
        // },
    ];

    const rows = [];

    scards && scards.forEach((scd, index) => {
        rows.push(
            {
                id: [scd._id, scd.doctor], pName: scd.patAggr.firstname, pGender: scd.patAggr.gender, pQType: scd.q_type.charAt(0).toUpperCase() + scd.q_type.slice(1).replace(/-/g, " "), pAgeGroup: scd.patAggr.age_group, pGeoLoc: scd.patAggr.geo_location, pDoctor: (scd.patAggr.doctor ? scd.patAggr.docAggr.firstname : ""), patID: parseInt(index) + 1, quesName: scd.jrSett.questionnaire_name
            }
        )
    })

    return (
        <Container maxWidth="">
            <Box>
                {!isEditing ?
                    <Grid container spacing={2} sx={{ flexDirection: { md: "row-reverse" } }}>
                        <Grid item xs={12} md={12}>
                            <Item sx={{ px: 3 }} style={spacingtitle}>
                                <Typography variant="h5">Scorecards</Typography>
                            </Item>

                            <Item>
                                <Box sx={{ height: 720, width: "100%" }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </Item>

                        </Grid>
                    </Grid>

                    :
                    <>
                        <Container maxWidth="xs">
                            <form className="create" onSubmit={handleSubmit}>
                                <Item sx={{ px: 3 }} style={spacingtitle}>
                                    <Typography variant="h5">Assign Doctor For {firstname}</Typography>
                                </Item>
                                <Item sx={{ px: 3 }}>
                                    <Grid container>
                                        <Grid>
                                            <Box sx={{ minWidth: 240 }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={docname}
                                                        label="Doctors"
                                                        onChange={(e) => setDocname(e.target.value)}
                                                    >
                                                        {doctors && doctors.map((dd, index) => (
                                                            <MenuItem key={dd._id} value={dd.firstname}>{dd.firstname}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Item>
                                <Item>
                                    <Button
                                        variant="contained"
                                        sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    <Button onClick={() => { setIsEditing(false) }}>Cancel</Button>
                                </Item>
                            </form>
                            {error && <Alert severity="error"><div className="error">{error}</div></Alert>}
                        </Container>
                    </>
                }
            </Box>
        </Container >
    )
}

export default ScoreCards
