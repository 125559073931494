import { useState, useEffect } from "react"

import DoctorForm from "../TemplateParts/DoctorForm"

import { useDoctorsContext } from "../hooks/useDoctorsContext"
import { useAuthContext } from '../hooks/useAuthContext'

import {
    Container,
    Box,
    Grid,
    styled,
    Paper,
    Typography,
    Button,
    Alert,
    TextField,
    IconButton,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    FormControl
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material/";

import { DataGrid, GridToolbarQuickFilter, GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                textAlign: 'right',
                m: 3,
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}

// Date fns
// import { formatDistance, subDays } from 'date-fns'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const Doctors = () => {

    const { doctors, dispatchDoctor } = useDoctorsContext()
    const { master, dispatch } = useAuthContext()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [usid, setUsid] = useState('')
    const [error, setError] = useState(null)
    const [emptyFields, setEmptyFields] = useState([])

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [isEditing, setIsEditing] = useState(false)

    const check = localStorage.getItem('master')

    const yesEditing = async (usrid) => {

        if (!check) {
            setError('You must be logged in')
            return
        }

        setIsEditing(true)
        const response1 = await fetch('/server/doctors/' + usrid, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })

        const json1 = await response1.json()

        if (response1.ok) {
            setError(null)
            setFirstname(json1.firstname)
            setLastname(json1.lastname)
            setPhone(json1.phone)
            setEmail(json1.email)
            setPassword(json1.password)
            setUsid(json1._id)
            setEmptyFields([])
        }
    }

    useEffect(() => {
        const fetchDoctors = async () => {
            if (check) {
                const response2 = await fetch('/server/doctors/', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${master.token}`
                    }
                })
                const json2 = await response2.json()

                if (response2.ok) {
                    //setDoctors(json)
                    dispatchDoctor({ type: 'LIST_DOCTORS', payload: json2 })
                } else {
                    dispatch({ type: "LOGOUT", isAuthenticated: false });
                }
            }
            else {
                dispatch({ type: 'LOGOUT', isAuthenticated: false })
            }
        }

        fetchDoctors()
    }, [dispatchDoctor])

    const handleClick = async (uid) => {

        if (!check) {
            setError('You must be logged in')
            return
        }
        const response3 = await fetch('/server/doctors/' + uid, {
            method: 'DELETE'
        })

        const json3 = await response3.json()

        if (response3.ok) {
            dispatchDoctor({ type: 'DEL_DOCTOR', payload: json3 })
        }
    }

    const handleSubmit = async (e) => {

        if (!check) {
            setError('You must be logged in')
            return
        }

        e.preventDefault()

        const doctor1 = { firstname, lastname, phone, email, password }

        const response0 = await fetch('/server/doctors/' + usid, {
            method: 'PATCH',
            body: JSON.stringify(doctor1),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${master.token}`
            }
        })

        const json4 = await response0.json()

        if (!response0.ok) {
            setError(json4.error)
            setEmptyFields(json4.emptyFields)
        }
        if (response0.ok) {
            dispatchDoctor({ type: 'UPDATE_DOCTOR', payload: json4 })
            setError(null)
            setFirstname('')
            setLastname('')
            setPhone('')
            setEmail('')
            setPassword('')
            setUsid('')
            setEmptyFields([])
            setIsEditing(false)
        }
    }

    const spacingtitle = {
        padding: "20px 25px 10px",
        textAlign: "left",
    };

    const columns = [
        {
            field: "userID",
            headerName: "ID",
            width: 90
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            editable: true,
        },
        {
            field: "mailId",
            headerName: "Mail Id",
            width: 300,
        },
        {
            field: "phNo",
            headerName: "Phone No",
            width: 150,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: "Actions",
            width: 250,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={'/viewdoctor/' + params.id}>
                            <Button sx={{ mr: 1 }} variant="outlined" color="info">
                                View
                            </Button>
                        </Link>
                        <Button
                            sx={{ mr: 1 }}
                            variant="outlined"
                            color="info"
                            onClick={() => yesEditing(params.id)}
                        >
                            Edit</Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => handleClick(params.id)}
                        >
                            Delete</Button>
                    </>
                )
            },
        },
    ];

    const rows = [];

    doctors && doctors.forEach((usr, index) => {
        rows.push(
            { id: usr._id, phNo: usr.phone, name: usr.firstname, mailId: usr.email, userID: parseInt(index) + 1 }
        );
    })

    return (
        <Container maxWidth="">
            <Box>
                {!isEditing ?
                    <Grid container spacing={2} sx={{ flexDirection: { md: "row-reverse" } }}>
                        <Grid item xs={12} md={4}>
                            <DoctorForm />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Item sx={{ px: 3 }} style={spacingtitle}>
                                <Typography variant="h5">Doctors</Typography>
                            </Item>

                            <Item>
                                <Box sx={{ height: 720, width: "100%" }}>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                        components={{ Toolbar: QuickSearchToolbar }}
                                    />
                                </Box>
                            </Item>


                            {/* <TableContainer sx={{ mb: 3 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow key={"prime"}>
                                            <TableCell>
                                                ID
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                First Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Last Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Mail ID
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Phone No
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {doctors && doctors.map((usr, index) => (
                                            <TableRow hover key={usr._id}>
                                                <TableCell>{parseInt(index) + 1}</TableCell>
                                                <TableCell>{usr.firstname}</TableCell>
                                                <TableCell>{usr.lastname}</TableCell>
                                                <TableCell>{usr.email}</TableCell>
                                                <TableCell>{usr.phone}</TableCell>
                                                <TableCell>
                                                    <Button sx={{ mr: 1 }} variant="outlined" color="info" onClick={() => yesEditing(usr._id)}>Edit</Button>
                                                    <Button variant="outlined" color="error" onClick={() => handleClick(usr._id)}>Delete</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}

                        </Grid>
                    </Grid>
                    :
                    <>
                        <Container maxWidth="xs">
                            <form className="create" onSubmit={handleSubmit}>
                                <Item sx={{ px: 3 }} style={spacingtitle}>
                                    <Typography variant="h5">Edit Doctor</Typography>
                                </Item>
                                <Item sx={{ px: 3 }}>
                                    <Grid container>
                                        <Grid md={6}>
                                            <Item sx={{ pl: 0 }}>
                                                <TextField
                                                    fullWidth
                                                    label="First Name"
                                                    id="firstname"
                                                    name="firstname"
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                    value={firstname}
                                                    inputProps={{ className: emptyFields?.includes('firstname') ? 'error' : '' }}
                                                />
                                            </Item>
                                        </Grid>
                                        <Grid md={6}>
                                            <Item sx={{ pr: 0 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Last Name"
                                                    id="lastname"
                                                    name="lastname"
                                                    onChange={(e) => setLastname(e.target.value)}
                                                    value={lastname}
                                                    inputProps={{ className: emptyFields?.includes('lastname') ? 'error' : '' }}
                                                />
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Item>
                                <Item sx={{ px: 3 }}>
                                    <TextField
                                        fullWidth
                                        label="Phone"
                                        id="phone"
                                        name="phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                        inputProps={{ className: emptyFields?.includes('phone') ? 'error' : '' }}
                                    />
                                </Item>
                                <Item sx={{ px: 3 }}>
                                    <TextField
                                        fullWidth
                                        label="Mail ID"
                                        id="email"
                                        name="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        inputProps={{ className: emptyFields?.includes('email') ? 'error' : '' }}
                                    />
                                </Item>

                                <Item sx={{ px: 3 }}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            name="password"
                                            autoComplete="password"
                                            inputProps={{ className: emptyFields?.includes('password') ? 'error' : '' }}
                                        />
                                    </FormControl>
                                </Item>

                                <Item>
                                    <Button
                                        variant="contained"
                                        sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                                        type="submit"
                                    >
                                        Update Doctor
                                    </Button>
                                    <Button onClick={() => { setIsEditing(false) }}>Cancel</Button>
                                </Item>
                            </form>
                            {error && <Alert severity="error"><div className="error">{error}</div></Alert>}
                        </Container>
                    </>
                }
            </Box>
        </Container>
    )
}

export default Doctors