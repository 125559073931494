// component
import SvgColor from "../../Components/Svgcolor/Svgcolor";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [

  {
    title: 'Dashboard',
    path: '/',
    icon: icon('ic_lock'),
    role: 'master'
  },

  // {
  //   title: 'Products',

  //   icon: icon('ic_lock'),
  //   submenu: [
  //     {
  //       title: 'List',
  //       path: 'prlist',
  //       icon: icon('ic_lock'),
  //     },
  //     {
  //       title: 'Category',
  //       path: 'pr-cat',
  //       icon: icon('ic_lock'),
  //     },

  //   ],
  // },


  {
    title: 'Doctors',
    path: 'doctors',
    icon: icon('ic_lock'),
    role: 'master'
  },
  {
    title: 'Patients',
    path: 'patients',
    icon: icon('ic_lock'),
    role: 'master'
  },
  {
    title: 'Journeys',
    path: 'questionnaires',
    icon: icon('ic_lock'),
    role: 'master'
  },
  {
    title: 'Score Cards',
    path: 'scorecards',
    icon: icon('ic_lock'),
    role: 'master'
  },
  {
    title: 'Patients',
    path: 'patients',
    icon: icon('ic_lock'),
    role: 'admin'
  },

];

export default navConfig;
