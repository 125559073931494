import { createContext, useReducer } from "react";

export const ScardContext = createContext()

export const scardReducer = (state, action) => {
    switch (action.type) {
        case 'LIST_SCARDS':
            return {
                scards: action.payload
            }
        case 'ASSIGN_DOCTOR':
            return {
                scards: state.scards.map((scard) => (scard._id === action.payload._id ? action.payload : scard))
            }
        default:
            return state
    }
}

export const ScardContextProvider = ({ children }) => {
    const [state, dispatchScards] = useReducer(scardReducer, {
        scards: null
    })

    return (
        <ScardContext.Provider value={{ ...state, dispatchScards }}>
            {children}
        </ScardContext.Provider>
    )
}
