import React, { useState, useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom"; //Navigate

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  styled,
  Paper,
  Alert,
  TextField,
} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import MDEditor from "@uiw/react-md-editor";

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

import { useAuthContext } from "../hooks/useAuthContext";
import { useQuesContext } from "../hooks/useQuesContext";
//import styled from '@mui/material/styles';

const QuesStyled = styled(ReactQuill)({
  "&.error": {
    border: "1px solid #ff0000",
  },
});

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        textAlign: "right",
        m: 3,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const Questionnaires = () => {
  const { ques, dispatchQues } = useQuesContext();
  const { master, dispatch } = useAuthContext();

  const [content, setContent] = useState("");
  const [terms, setTerms] = useState("");
  const [qsid, setQsid] = useState("");
  const [quesname, setQuesname] = useState("");
  const [thankYouMsg, setThankYouMsg] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  useEffect(() => {
    const check = localStorage.getItem("master");
    const fetchQues = async () => {
      const response2 = await fetch("/server/ques/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${master.token}`,
        },
      });
      const json2 = await response2.json();

      if (response2.ok) {
        //setDoctors(json)
        dispatchQues({ type: "LIST_QUES", payload: json2 });
      }
    };
    if (check) {
      fetchQues();
    } else {
      dispatch({ type: "LOGOUT", isAuthenticated: false });
    }
  }, [dispatchQues, dispatch]);

  const yesEditing = async (qqid) => {
    const check = localStorage.getItem("master");
    if (!check) {
      //setError('You must be logged in')
      return <Navigate to="/signin" />;
    }

    setIsEditing(true);
    const response1 = await fetch("/server/ques/" + qqid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${master.token}`,
      },
    });

    const json1 = await response1.json();

    if (response1.ok) {
      setError(null);
      setContent(json1.content);
      setTerms(json1.termsconditions);
      setQsid(json1._id);
      setQuesname(json1.questionnaire_name);
      setThankYouMsg(json1.thank_you_msg);
      setEmptyFields([]);
    }
  };

  const handleSubmit = async (e) => {
    const check = localStorage.getItem("master");
    if (!check) {
      setError("You must be logged in");
      return;
    }

    e.preventDefault();

    const qdets = { quesname, content, terms, thankYouMsg };

    const response0 = await fetch("/server/ques/updatecontent/" + qsid, {
      method: "POST",
      body: JSON.stringify(qdets),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${master.token}`,
      },
    });

    const json4 = await response0.json();

    if (!response0.ok) {
      setError(json4.error);
      setEmptyFields(json4.emptyFields);
    }
    if (response0.ok) {
      dispatchQues({ type: "UPDATE_QUES_CONTENT", payload: json4 });
      setError(null);
      setContent("");
      setTerms("");
      setQsid("");
      setQuesname("");
      setThankYouMsg("");
      setEmptyFields([]);
      setIsEditing(false);
    }
  };

  const spacingtitle = {
    padding: "20px 25px 10px",
    textAlign: "left",
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleQuillChange = (e) => {
    setContent(e.target.value);
  };

  // const editor = ReactQuill.getEditor();
  // const unprivilegedEditor = ReactQuill.makeUnprivilegedEditor(editor);

  const columns = [
    {
      field: "qqID",
      headerName: "ID",
      width: 200,
    },
    {
      field: "quesname",
      headerName: "Journey Name",
      width: 300,
      editable: true,
    },
    {
      field: "quesgender",
      headerName: "Gender",
      width: 100,
      editable: true,
    },
    {
      field: "quesperiod",
      headerName: "Period",
      width: 100,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            color="info"
            onClick={() => yesEditing(params.id)}
          >
            Update Content
          </Button>
        );
      },
    },
  ];

  const rows = [];

  ques &&
    ques.forEach((qq, index) => {
      rows.push({
        id: qq._id,
        quesname: qq.questionnaire_name,
        quesgender: qq.ques_gender.charAt(0).toUpperCase() + qq.ques_gender.slice(1),
        quesperiod: qq.ques_period + ' days',
        qqID: parseInt(index) + 1,
      })
    })

  return (
    <Container maxWidth="">
      <Box>
        {!isEditing ? (
          <Grid
            container
            spacing={2}
            sx={{ flexDirection: { md: "row-reverse" } }}
          >
            <Grid item xs={12} md={12}>
              <Item sx={{ px: 3 }} style={spacingtitle}>
                <Typography variant="h5">Journeys</Typography>
              </Item>

              <Item>
                <Box sx={{ height: 700, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: QuickSearchToolbar }}
                  />
                </Box>
              </Item>

              {error && (
                <Alert severity="error">
                  <div className="error">{error}</div>
                </Alert>
              )}
              {/* <TableContainer sx={{ mb: 3 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow key={"prime"}>
                                            <TableCell>
                                                ID
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Journey Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ques && ques.map((qq, index) => (
                                            <TableRow hover key={qq._id}>
                                                <TableCell>{parseInt(index) + 1}</TableCell>
                                                <TableCell>{qq._id}</TableCell>
                                                <TableCell>
                                                    <Button sx={{ mr: 1 }} variant="outlined" color="info" onClick={() => yesEditing(qq._id)}>Update Content</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}
            </Grid>
          </Grid>
        ) : (
          <>
            <Container maxWidth="md">
              <Typography variant="h4">
                Edit Journey {quesname}
              </Typography>
              <form className="create" onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={2}
                  sx={({ flexDirection: { md: "row" } }, { mt: 2 })}
                >
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Journey Name"
                      //id="quesname"
                      //name="quesname"
                      onChange={(e) => setQuesname(e.target.value)}
                      value={quesname}
                      inputProps={{
                        className: emptyFields?.includes("quesname")
                          ? "error"
                          : "",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={({ flexDirection: { md: "row" } }, { mt: 2 })}
                >
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6">Journey Content</Typography>
                    <QuesStyled
                      placeholder="Journey Content"
                      value={content}
                      onChange={setContent}
                      className={
                        emptyFields?.includes("content") ? "error" : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={({ flexDirection: { md: "row" } }, { mt: 2 })}
                >
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6">Terms And Conditions</Typography>
                    <TextareaAutosize
                      placeholder="Terms & Conditions"
                      value={terms}
                      className={emptyFields?.includes("terms") ? "error" : ""}
                      style={{ width: "100%" }}
                      onChange={(e) => setTerms(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={({ flexDirection: { md: "row" } }, { mt: 2 })}
                >
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6">Thank You Message</Typography>
                    <QuesStyled
                      placeholder="Thank You Message"
                      value={thankYouMsg}
                      onChange={setThankYouMsg}
                      className={
                        emptyFields?.includes("thankYouMsg") ? "error" : ""
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  sx={{ my: 2, mr: 2 }}
                  type="submit"
                  variant="outlined"
                  color="info"
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setIsEditing(false);
                    setError(null);
                  }}
                >
                  Cancel
                </Button>
              </form>
              {error && (
                <Alert severity="error">
                  <div className="error">{error}</div>
                </Alert>
              )}
            </Container>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Questionnaires;
