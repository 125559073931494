import { useState, useEffect } from "react";

import { useAuthContext } from "../hooks/useAuthContext";
import { usePatientsContext } from "../hooks/usePatientsContext";
import { useDoctorsContext } from "../hooks/useDoctorsContext";

import {
  Container,
  Box,
  Grid,
  styled,
  Paper,
  Typography,
  Button,
  Alert,
} from "@mui/material";

import Link from "@mui/material/Link";

import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import Badge from '@mui/material/Badge';

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        textAlign: "right",
        m: 3,
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function formatDate(newDate) {
  const months = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sept',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  }
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const d = new Date(newDate)
  const year = d.getFullYear()
  const date = d.getDate()
  const monthIndex = d.getMonth()
  const monthName = months[d.getMonth()]
  const dayName = days[d.getDay()] // Thu
  const formatted = `${date} ${monthName} ${year}`
  return formatted.toString()
}

const Patients = () => {
  const { patients, dispatchPatient } = usePatientsContext();
  const { doctors, dispatchDoctor } = useDoctorsContext();
  const { master, dispatch } = useAuthContext();

  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [docname, setDocname] = useState("");
  const [usid, setUsid] = useState("");
  const [curDoc, setCurDoc] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [docDets, setDocDets] = useState("");
  const [isDocView, setIsDocView] = useState(false);

  const check = localStorage.getItem("master");
  useEffect(() => {
    const fetchPatients = async () => {
      if (check) {
        const response1 = await fetch("/server/doctors/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${master.token}`,
          },
        });
        const json1 = await response1.json();

        if (response1.ok) {
          dispatchDoctor({ type: "LIST_DOCTORS", payload: json1 });
        } else {
          dispatch({ type: "LOGOUT", isAuthenticated: false });
        }

        const response2 = await fetch("/server/patients/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${master.token}`,
          },
        });

        const json2 = await response2.json();

        if (response2.ok) {
          dispatchPatient({ type: "LIST_PATIENTS", payload: json2 });
        }
      } else {
        dispatch({ type: "LOGOUT", isAuthenticated: false });
      }
    };

    fetchPatients();
  }, [dispatchPatient, dispatchDoctor]);

  const assignDoc = async (usid) => {
    if (!check) {
      setError("You must be logged in");
      return;
    }

    setIsEditing(true);

    const response0 = await fetch("/server/patients/" + usid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${master.token}`,
      },
    });
    const json4 = await response0.json();

    if (!response0.ok) {
      setError(json4.error);
      setEmptyFields(json4.emptyFields);
    }
    if (response0.ok) {
      setFirstname(json4.patient.firstname);
      setUsid(json4.patient._id);
      setCurDoc(json4.patsDoc._id);
      setError(null);
      setEmptyFields([]);
    }
  };

  const viewDoc = async (docname) => {
    if (!check) {
      setError("You must be logged in");
      return;
    }

    setIsEditing(true);
    setIsDocView(true);
    const response1 = await fetch("/server/doctors/" + docname, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${master.token}`,
      },
    });

    const json5 = await response1.json();

    if (!response1.ok) {
      setError(json5.error);
    }
    if (response1.ok) {
      setDocDets(json5);
      //console.log(docDets);
    }
  };

  const setFalseStatus = async (e) => {
    setIsEditing(false);
    setIsDocView(false);
  };

  const handleSubmit = async (e) => {
    if (!check) {
      setError("You must be logged in");
      return;
    }

    e.preventDefault();

    const docdets = { docname };

    const response0 = await fetch("/server/patients/assigndoctor/" + usid, {
      method: "POST",
      body: JSON.stringify(docdets),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${master.token}`,
      },
    });

    const json4 = await response0.json();

    if (!response0.ok) {
      setError(json4.error);
      setEmptyFields(json4.emptyFields);
    }
    if (response0.ok) {
      dispatchPatient({ type: "ASSIGN_DOCTOR", payload: json4 });
      setError(null);
      setFirstname("");
      setDocname("");
      setUsid("");
      setEmptyFields([]);
      setIsEditing(false);
    }
  };

  const spacingtitle = {
    padding: "20px 25px 10px",
    textAlign: "left",
  };
        
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            top: '-12px',
            right: '-12px',
            background: '#1890FF'
        },
      }));

  const columns = [
    {
      field: "crDate",
      headerName: "Date",
      width: 120,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
      editable: true,
    },
    {
      field: "mailId",
      headerName: "Mail ID",
      width: 200,
    },
    {
      field: "phNo",
      headerName: "Phone No",
      width: 150,
    },
    {
      field: "patGender",
      headerName: "Gender",
      width: 80,
    },
    {
      field: "ageGrp",
      headerName: "Age Group",
      width: 100,
    },
    {
      field: "docName",
      type: "actions",
      headerName: "Doctor",
      width: 180,
      renderCell: (params) => {
        return params.id[1] ? (
          <>
            <Button
              variant="outlined"
              color="info"
              onClick={() => viewDoc(params.id[1])}
            >
              View Doctor
            </Button>{" "}
          </>
        ) : (
          ""
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Action",
      width: 180,
      renderCell: (params) => {
        return params.id[1] ? (
          <>
            <Button
              variant="outlined"
              color="info"
              onClick={() => assignDoc(params.id[0])}
            >
              Modify Doctor
            </Button>{" "}
          </>
        ) : (
          <Button
            variant="outlined"
            color="info"
            onClick={() => assignDoc(params.id[0])}
          >
            Assign Doctor
          </Button>
        );
      },
    },
    {
      field: "questionnaire",
      type: "actions",
      headerName: "Journeys",
      width: 180,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            color="info"
            component={Link}
            href={"view-questionnaires/" + params.id[0]}
          >
            View Journeys<StyledBadge badgeContent={params.id[3]} color="secondary"></StyledBadge>
          </Button>
        );
      },
    },
  ];

  const rows = [];

  patients &&
    patients.forEach((usr, index) => {
      rows.push({
        crDate: formatDate(usr.createdAt),
        id: [usr._id, usr.doctor, usr.userid, usr.jrSett && usr.jrSett.total_jrs],
        firstname: usr.firstname,
        lastname: usr.lastname,
        mailId: usr.email,
        phNo: usr.phone,
        patGender: usr.gender,
        ageGrp: usr.age_group,
        docName: usr.doctor,
        patID: parseInt(index) + 1,
      });

      // rows.push(
      //     { id: [usr._id, usr.doctor], firstname: usr.firstname, lastname: usr.lastname, mailId: usr.email, phNo: usr.phone, patID: parseInt(index) + 1 }
      // );
    });

  return (
    <Container maxWidth="">
      <Box>
        {!isEditing ? (
          <Grid
            container
            spacing={2}
            sx={{ flexDirection: { md: "row-reverse" } }}
          >
            <Grid item xs={12} md={12}>
              <Item sx={{ px: 3 }} style={spacingtitle}>
                <Typography variant="h5">Patients</Typography>
              </Item>

              <Item>
                <Box sx={{ height: 720, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{ Toolbar: QuickSearchToolbar }}
                    columnVisibilityModel={{ actions: master.mRole === "master" ? true : false }}
                  />
                </Box>
              </Item>

              {/* <TableContainer sx={{ mb: 3 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow key={"prime"}>
                                            <TableCell>
                                                ID
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                First Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Last Name
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Mail ID
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Phone No
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Doctor
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patients && patients.map((usr, index) => (
                                            <TableRow hover key={usr._id}>
                                                <TableCell>{parseInt(index) + 1}</TableCell>
                                                <TableCell>{usr.firstname}</TableCell>
                                                <TableCell>{usr.lastname}</TableCell>
                                                <TableCell>{usr.email}</TableCell>
                                                <TableCell>{usr.phone}</TableCell>
                                                <TableCell> {usr.doctor ? <><p>{usr.doctor}</p></> : ''}</TableCell>
                                                <TableCell>
                                                    {usr.doctor ? <><Button
                                                        variant="outlined"
                                                        color="info"
                                                        onClick={() => assignDoc(usr._id)}
                                                    >
                                                        Modify
                                                    </Button> </> :
                                                        <Button
                                                            variant="outlined"
                                                            color="info"
                                                            onClick={() => assignDoc(usr._id)}
                                                        >
                                                            Assign
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}
            </Grid>
          </Grid>
        ) : (
          <>
            <Container maxWidth="xs">
              {!isDocView ? (
                <>
                  <form className="create" onSubmit={handleSubmit}>
                    <Item sx={{ px: 3 }} style={spacingtitle}>
                      <Typography variant="h5">
                        Assign Doctor For {firstname}
                      </Typography>
                    </Item>
                    <Item sx={{ px: 3 }}>
                      <Grid container>
                        <Grid>
                          <Box sx={{ minWidth: 240 }}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Doctor
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={docname ? docname : curDoc}
                                label="Doctors"
                                onChange={(e) => setDocname(e.target.value)}
                              >
                                {doctors &&
                                  doctors.map((dd, index) => (
                                    <MenuItem key={dd._id} value={dd._id}>
                                      {dd.firstname}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </Item>
                    <Item>
                      <Button
                        variant="contained"
                        sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                        type="submit"
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => {
                          setIsEditing(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Item>
                  </form>
                  <div>
                    {error && (
                      <Alert severity="error">
                        <div className="error">{error}</div>
                      </Alert>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <Item sx={{ px: 3 }}>
                    <Typography variant="h4" sx={{ py: 2 }}>
                      Doctor Details
                    </Typography>
                    <Grid container style={{ display: "block" }}>
                      <Grid>
                        <Box sx={{ minWidth: 240 }}>
                          <Card sx={{}}>
                            <CardContent>
                              <Typography variant="h5" component="div">
                                {docDets.firstname}
                              </Typography>
                              <Typography
                                sx={{ mb: 1.5 }}
                                color="text.secondary"
                              >
                                {docDets.lastname}
                              </Typography>
                              <Typography variant="h6" component="div">
                                {docDets.email}
                              </Typography>
                              <Typography variant="h6" component="div">
                                {docDets.phone}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>
                  </Item>
                  <Item>
                    <Button
                      variant="contained"
                      sx={{ my: 2, background: "rgb(0, 171, 85)" }}
                      onClick={() => {
                        setFalseStatus();
                      }}
                    >
                      Back To Patients List
                    </Button>
                  </Item>
                </>
              )}
            </Container>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Patients;
