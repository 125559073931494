// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

import { BrowserRouter } from 'react-router-dom';

import './App.css';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}





// // routes
// import Router from './routes';
// import ThemeProvider from './theme';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
// import { useAuthContext } from './hooks/useAuthContext'

// // pages & components
// import SignIn from './pages/SignIn'
// import Dashboard from './Dashboard/Dashboard'

// function App() {

//   const { master } = useAuthContext()

//   return (
//     <ThemeProvider>
//       <div className="App">
//         <BrowserRouter>
//           <div className="pages" data-test={master ? "logged In" : "logged out"}>
//             <Routes>
//               <Route
//                 path="/"
//                 element={master ? <Dashboard /> : <Navigate to="/" />}
//               />
//               <Route
//                 path="signin"
//                 element={!master ? <SignIn /> : <Navigate to="/" />}
//               />
//             </Routes>
//           </div>
//         </BrowserRouter>
//       </div>
//     </ThemeProvider>
//   );
// }

// export default App;
